export const getPercentageFormat = (n) => {
  return {
    decimal: ",",
    thousands: ".",
    prefix: "",
    suffix: " %",
    precision: 3,
  };
};

export const getFloatFormat = (n) => {
  return {
    decimal: ",",
    thousands: "",
    prefix: "",
    suffix: "",
    precision: 3,
  };
};

export const getBRLFormat = () => {
  return {
    decimal: ",",
    thousands: ".",
    prefix: "R$ ",
    suffix: "",
    precision: 2,
  };
};
