<template>
  <form
    id="futuro-customer-interest-in-living-abroad"
    class="my-5"
    @submit.prevent
  >
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-travel.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Investimentos internacionais</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mb-3 gy-3">
        <div class="col-md-6 col-12">
          <label for="isSaving" class="form-label"
            >Você pensa em viver ou viajar para o exterior?</label
          >
          <select
            id="isSaving"
            v-model="isSaving"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @change="handleChange"
          >
            <option value="" disabled selected>Selecione</option>
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
        </div>
      </div>
      <div v-if="isSaving === 'yes'" class="row mb-3 gy-3">
        <div class="col-md-6 col-12">
          <label for="interestDate" class="form-label"
            >Quando você pretende viver no exterior?</label
          >
          <input
            id="interestDate"
            v-model="interestDate"
            :disabled="isDetail"
            type="date"
            class="form-control"
            :class="{ 'is-invalid': v$.interestDate.$error }"
            @blur="preSave()"
          />
          <div class="invalid-feedback">
            <span v-if="v$.interestDate.required">A data é obrigatória</span>
          </div>
        </div>
      </div>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { minLength } from "@vuelidate/validators";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { integerToString, floatToMoney } from "@/helpers/converters";
import { getInterviewInterestInLivingAbroadPayload } from "@/helpers/interview_payloads";
import * as types from "../store/types";
import Spinner from "@/modules/shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      wantSave: "",
      isSaving: "",
      interestDate: "",
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      interestDate: {
        required: (val) => !!val || this.isSaving === "no",
      },
      isSaving: {
        required: (val) => val !== "",
      },
    };
  },
  computed: {
    ...mapGetters({
      formatedCurrency: types.FORMATED_CURRENCY,
    }),
    moneyFormat: function () {
      return this.formatedCurrency;
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getInterestInLivingAbroad: types.GET_INTEREST_IN_LIVING_ABROAD,
      autoSaveInterestInLivingAbroad: types.AUTO_SAVE_INTEREST_IN_LIVING_ABROAD,
      deleteInterestInLivingAbroad: types.DELETE_INTEREST_IN_LIVING_ABROAD,
    }),
    ...mapMutations({
      mutateInterestInLivingAbroad: types.MUTATE_INTEREST_IN_LIVING_ABROAD,
    }),
    async handleChange() {
      if (this.isSaving === "no") {
        await this.deleteInterest();
      }
    },
    async deleteInterest() {
      this.loading = true;
      try {
        await this.deleteInterestInLivingAbroad(this.token);
        this.interestDate = "";
        this.mutateInterestInLivingAbroad(null);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível excluir os dados de investimentos internacionais do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchInterestInLivingAbroad() {
      this.loading = true;
      try {
        const response = await this.getInterestInLivingAbroad(
          this.$route.params.customerId
        );
        const { interested, interest_date } = response.data;
        this.isSaving = interested ? "yes" : "no";
        this.interestDate = interest_date || "";
        const payload = getInterviewInterestInLivingAbroadPayload(this);
        this.mutateInterestInLivingAbroad(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter os dados de investimentos internacionais do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchInterestInLivingAbroad()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    preSave() {
      const payload = getInterviewInterestInLivingAbroadPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateInterestInLivingAbroad(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveInterestInLivingAbroad(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente os dados de investimentos internacionais do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
