<template>
  <form
    v-if="isInterview"
    id="futuro-customer-patrimony-sum"
    class="my-5"
    @submit.prevent
  >
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            v-if="!showPatrimony"
            src="~@/assets/img/chevron-down-solid.svg"
            alt="Expandir Patrimônio"
            @click="showPatrimony = !showPatrimony"
          />
          <img
            v-if="showPatrimony"
            src="~@/assets/img/chevron-up-solid.svg"
            alt="Recolher Patrimônio"
            @click="showPatrimony = !showPatrimony"
          />
          <h2>Inventário previsto</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>

      <div v-if="showPatrimony && !loading && patrimonyData">
        <div class="row justify-content-center mt-3 mb-3">
          <div class="col-auto blue-card patrimony-sum">
            <div style="display: flex; align-items: center; justify-content: center;">
              <h1 class="mb-0" style="margin-right: 10px;">Custo de inventário previsto</h1>
              <span style="position: relative;">
                <img
                  src="@/assets/img/info-circle.svg"
                  alt="Info"
                  @mouseover="showTooltip = true"
                  @mouseleave="showTooltip = false"
                  style="width: 18px; height: 18px; cursor: pointer;"
                />
                <span
                  v-show="showTooltip"
                  style="
                    width: 200px;
                    background: linear-gradient(252deg, #6b6bfd, #2486e8);
                    color: #fff;
                    text-align: center;
                    border-radius: 5px;
                    padding: 5px;
                    position: absolute;
                    z-index: 1;
                    bottom: 125%;
                    left: 50%;
                    margin-left: -100px;
                    opacity: 1;
                    transition: opacity 0.3s;
                    font-size: 12px;
                  "
                  class="tooltip-text"
                >
                Os custos relacionados ao inventário podem variar entre ITCMD e honorários advocatícios, situando-se entre 10% e 25%
                </span>
              </span>
            </div>
            <p class="mt-3">
              <b>{{ formatCurrency(patrimonyData.soma_bens) }}</b> em bens
            </p>
            <p>
              <b>{{ formatCurrency(patrimonyData.soma_imoveis) }}</b> em imóveis
            </p>
            <p>
              <b>{{ formatCurrency(patrimonyData.soma_fipe) }}</b> em veículos
            </p>
            <p>
              <b>{{ formatCurrency(patrimonyData.soma_investimento) }}</b> em
              investimentos
            </p>
            <p>
              <b>{{ formatCurrency(patrimonyData.soma_patrimonio) }}</b> em
              patrimônios total
            </p>
            <p class="final-value mt-3">
              <b>{{ formatCurrency(patrimonyData.dez_porcento_patrimonio) }}</b>
              em inventário
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import Spinner from "../../shared/components/Spinner";

export default {
  components: {
    Spinner,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPatrimony: true,
      showTooltip: false
    };
  },
  computed: {
    ...mapGetters({
      patrimonySum: types.PATRIMONY_SUM,
      loading: types.LOADING_PATRIMONY,
      formatCurrency: types.FORMAT_CURRENCY,
    }),
    patrimonyData() {
      return this.patrimonySum
        ? {
            soma_patrimonio: Number(this.patrimonySum.soma_patrimonio) || 0,
            soma_bens: Number(this.patrimonySum.soma_bens) || 0,
            soma_imoveis: Number(this.patrimonySum.soma_imoveis) || 0,
            soma_fipe: Number(this.patrimonySum.soma_fipe) || 0,
            soma_investimento: Number(this.patrimonySum.soma_investimento) || 0,
            dez_porcento_patrimonio:
              Number(this.patrimonySum.dez_porcento_patrimonio) || 0,
          }
        : {
            soma_patrimonio: 0,
            soma_bens: 0,
            soma_imoveis: 0,
            soma_fipe: 0,
            soma_investimento: 0,
            dez_porcento_patrimonio: 0,
          };
    },
  },
  mounted() {
    this.token = this.$route.params.customerId;
    this.fetchAll();
  },
  methods: {
    fetchAll() {
      this.$store.dispatch(types.FETCH_PATRIMONY_SUM, this.token);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 16px;
  margin: 0;
  b {
    font-weight: 600;
  }
}
.blue-card {
  &.totalizer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;
  }
  &.patrimony-sum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: col-600px;
    height: auto;
    padding: 35px 55px;
    h1 {
      margin-bottom: 20px;
    }
  }
}
.final-value {
  font-size: 26px !important;
  line-height: 1.2 !important;
  font-weight: 600;
  color: #ffeb98;
}
</style>
