<template>
  <form v-if="isInterview" @submit.prevent>
    <div class="card my-5">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-wallet.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Pagamento do projeto financeiro (não inclui assinatura)</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row px-3">
        <div class="col-md-6 col-12 my-3">
          <label for="config" class="form-label">
            Escolha a forma como o cliente irá pagar
          </label>
          <select
            id="config"
            v-model="config"
            class="form-select"
            @change="(event) => configurePayments(event)"
          >
            <option
              v-for="key in Object.keys(paymentConfigs)"
              :key="key"
              :value="key"
            >
              <span>
                {{ paymentConfigs[key] }}
              </span>
            </option>
          </select>
        </div>
      </div>
      <div v-if="totalValue !== transactionsTotalValue" class="row mt-3 px-3">
        <div class="col-12 totalizer red-container">
          <span
            >ATENÇÃO: as formas de pagamento não serão salvas enquanto houver
            divergência entre o total dos pagamentos e o valor total do
            investimento na consultoria</span
          >
        </div>
      </div>
      <div class="row justify-content-center px-3">
        <div
          v-for="(transactionConfiguration, index) in transactions"
          :key="`config-${index}`"
          class="inner-card mt-3"
          style="padding: 20px"
        >
          <div class="row mb-3">
            <div class="col-12">
              <h2>
                <span style="float: left">{{ index + 1 }}º pagamento</span>
              </h2>
            </div>
          </div>
          <div class="row justify-content-around">
            <div class="col-md-3 col-12">
              <label :for="`configAmount-${index}`" class="form-label">
                Valor a pagar
              </label>
              <input
                :id="`configAmount-${index}`"
                v-model.lazy="transactionConfiguration.amount"
                v-money="moneyFormat"
                min="0"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.transactions.$dirty &&
                    v$.transactions.$each.$response.$errors[index].amount
                      .length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.transactions.$dirty &&
                    v$.transactions.$each.$response.$errors[index].amount.length
                  "
                >
                  Digite um valor válido
                </span>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="row flex-column">
                <label class="form-label"> Forma de pagamento </label>
                <span class="mt-2">
                  <b>
                    {{
                      transactionPaymentTypes[
                        transactionConfiguration.paymentTypeId
                      ]
                    }}
                  </b>
                </span>
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.transactions.$dirty &&
                      v$.transactions.$each.$response.$errors[index]
                        .paymentTypeId.length
                    "
                  >
                    Forma de pagamento não informada
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="row flex-column">
                <label class="form-label"> Quantidade de parcelas </label>
                <span class="mt-2">
                  <b>
                    {{ transactionConfiguration.installments }}
                  </b>
                </span>
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.transactions.$dirty &&
                      v$.transactions.$each.$response.$errors[index]
                        .installments.length
                    "
                  >
                    Não foi informada a quantidade de parcelas
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div
                v-if="transactionConfiguration.installments > 1"
                class="row flex-column"
              >
                <label class="form-label"> Valor das parcelas </label>
                <span class="mt-2">
                  <b
                    >{{ transactionConfiguration.installments }}x de
                    {{
                      formatCurrency(
                        getInstallmentValue(transactionConfiguration),
                        "BRL"
                      )
                    }}
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 px-3">
        <div
          class="col-12 totalizer"
          :class="{
            'green-container': totalValue === transactionsTotalValue,
            'red-container': totalValue !== transactionsTotalValue,
          }"
        >
          <span>Total dos pagamentos:</span>
          <span
            >{{ formatCurrency(transactionsTotalValue, "BRL") }}
            {{
              totalValue !== transactionsTotalValue
                ? getRemaingAmountMessage()
                : ""
            }}</span
          >
        </div>
      </div>
      <div v-if="totalValue" class="row my-3 px-3">
        <div class="col-12 blue-card totalizer">
          <span>Valor total do investimento no projeto financeiro:</span>
          <span>{{ formatCurrency(totalValue, "BRL") }}</span>
        </div>
      </div>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import { VMoney } from "v-money";
import {
  helpers,
  required,
  minValue,
  maxValue,
  minLength,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import { getTransactionConfigurationsPayload } from "@/helpers/interview_payloads";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as transactionPaymentTypes from "@/constants/transactionPaymentTypes";
import Spinner from "@/modules/shared/components/Spinner";
import CommentsSection from "./CommentsSection";
import { getBRLFormat } from "@/helpers/formatting";

export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      v$: useVuelidate({ $registerAs: props.interviewSection }),
    };
  },
  data() {
    return {
      token: "",
      loading: false,
      deleting: -1,
      transactions: [],
      config: "1_0",
      paymentConfigs: {
        "1_0": "Pix (à vista)",
        "0_1": "Apenas um cartão de crédito",
        "1_1": "Entrada no Pix + Um cartão de crédito",
        "0_2": "Dois cartões de crédito",
        "1_2": "Entrada no Pix + Dois cartões de crédito",
      },
    };
  },
  validations() {
    return {
      transactions: {
        $each: helpers.forEach({
          id: {},
          paymentTypeId: { required },
          installments: {
            minValue: minValue(1),
            maxValue: maxValue(12),
          },
          amount: {
            minLength: minLength(7),
            greaterThanZero: (value) => {
              return moneyToFloat(value) > 0;
            },
          },
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      proposal: types.PROPOSAL,
      calculation: types.PROPOSAL_CALCULATION,
      transactionPaymentTypes: sharedTypes.TRANSACTION_PAYMENT_TYPES,
      formatCurrency: types.FORMAT_CURRENCY,
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },
    transactionInstallments() {
      return _.range(1, 13);
    },
    totalValue() {
      const key = ((this.proposal?.installment_quantity || 0) - 1).toString();
      if (
        this.calculation?.parcels_values &&
        key in this.calculation?.parcels_values
      ) {
        const total = this.calculation?.parcels_values[key].total_amount || 0;
        return parseFloat(total);
      }
      return 0;
    },
    transactionsTotalValue() {
      let total = 0;
      this.transactions.forEach((transaction) => {
        total += moneyToFloat(transaction.amount);
      });
      return parseFloat(total.toFixed(2));
    },
    creditCardTransactionPaymentType() {
      return transactionPaymentTypes.CREDIT_CARD.toString();
    },
  },
  watch: {
    proposal(newVal) {
      if (this.proposal?.installment_quantity && this.proposal?.total_amount) {
        setTimeout(
          () =>
            this.changePaymentInstallments(this.proposal?.installment_quantity),
          2000
        );
      }
    },
    calculation(newVal) {
      if (
        newVal?.parcels_values &&
        "0" in newVal?.parcels_values &&
        this.transactionsTotalValue !==
          newVal?.parcels_values["0"].total_amount &&
        this.proposal?.installment_quantity
      ) {
        setTimeout(
          () =>
            this.changePaymentInstallments(this.proposal?.installment_quantity),
          2000
        );
      }
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    this.fetchAll();
  },
  methods: {
    ...mapActions({
      getTransactionPaymentTypes: sharedTypes.GET_TRANSACTION_PAYMENT_TYPES,
      getTransactionPayments: types.GET_TRANSACTION_PAYMENTS,
      autoSaveTransactionPayments: types.AUTO_SAVE_TRANSACTION_PAYMENTS,
      deleteTransactionPayment: types.DELETE_TRANSACTION_PAYMENT,
    }),
    ...mapMutations({
      mutateTransactionPayments: types.MUTATE_TRANSACTION_PAYMENTS,
      changeLoadingState: types.MUTATE_INTERVIEW_PROPOSAL_LOADING,
    }),
    async fetchAll() {
      this.changeLoadingState(true);
      try {
        await Promise.all([
          this.getTransactionPaymentTypes(),
          this.getTransactions(),
        ]);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter as configurações do pagamento.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.changeLoadingState(false);
      }
    },
    getTransactions() {
      return this.getTransactionPayments(this.token).then((response) => {
        this.fillTransactions(response.data?.transactions);
        const payload = getTransactionConfigurationsPayload(this);
        this.mutateTransactionPayments(payload);
        return Promise.resolve(response);
      });
    },
    fillTransactions(transactions) {
      let pixTransactionsQuantity = 0;
      let creditCardTransactionsQuantity = 0;
      this.transactions = transactions.map((transaction) => {
        const { id, payment_type_id, installments, amount } = transaction;
        return {
          id,
          paymentTypeId: payment_type_id,
          installments,
          amount: floatToMoney(amount),
        };
      });
      this.transactions.forEach((transaction) => {
        if (transaction.paymentTypeId === transactionPaymentTypes.PIX) {
          pixTransactionsQuantity++;
        }
        if (transaction.paymentTypeId === transactionPaymentTypes.CREDIT_CARD) {
          creditCardTransactionsQuantity++;
        }
      });
      if (
        (pixTransactionsQuantity === 0 &&
          creditCardTransactionsQuantity === 0) ||
        pixTransactionsQuantity > 1 ||
        creditCardTransactionsQuantity > 2
      ) {
        pixTransactionsQuantity = 1;
        creditCardTransactionsQuantity = 0;
        this.transactions = [
          {
            id: null,
            paymentTypeId: transactionPaymentTypes.PIX,
            installments: 1,
            amount: floatToMoney(this.totalValue),
          },
        ];
      }
      this.config = `${pixTransactionsQuantity}_${creditCardTransactionsQuantity}`;
      this.fixTotalTransactionsValue();
    },
    preSave() {
      const payload = getTransactionConfigurationsPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateTransactionPayments(payload);
        this.autoSave(payload);
      }
    },
    async autoSave(payload) {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect || this.totalValue !== this.transactionsTotalValue)
        return;
      this.loading = true;
      this.autoSaveTransactionPayments(payload)
        .then((response) => {
          this.fillTransactions(response.data?.transactions);
          const payload = getTransactionConfigurationsPayload(this);
          this.mutateTransactionPayments(payload);
        })
        .catch((error) => {
          let messages = [
            "Não foi possível salvar automaticamente a proposta do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ];
          if (error.response?.data?.message) {
            messages = [error.response.data.message];
          }
          this.$vfm.show("message", {
            type: "error",
            messages,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInstallmentValue(transactionConfiguration) {
      return (
        moneyToFloat(transactionConfiguration.amount) /
        parseInt(transactionConfiguration.installments || 1)
      );
    },
    getRemaingAmountMessage() {
      if (this.transactionsTotalValue > this.totalValue) {
        return "(valor diferente do total do investimento)";
      } else if (this.transactionsTotalValue < this.totalValue) {
        return `(adicione os ${this.formatCurrency(
          this.totalValue - this.transactionsTotalValue,
          "BRL"
        )} restantes)  `;
      }
      return "";
    },
    configurePayments(event) {
      let newTransactions = [];
      const items = event.target.value.split("_");
      if (items.length === 2) {
        const pixTransactionsQuantity = parseInt(items[0] || "0");
        const creditCardTransactionsQuantity = parseInt(items[1] || "0");
        const totalQuantity =
          pixTransactionsQuantity + creditCardTransactionsQuantity;
        if (pixTransactionsQuantity) {
          for (let i = 0; i < pixTransactionsQuantity; i++) {
            newTransactions.push({
              id: null,
              paymentTypeId: transactionPaymentTypes.PIX,
              installments: 1,
              amount: floatToMoney(
                parseFloat((this.totalValue / totalQuantity).toFixed(2))
              ),
            });
          }
        }
        if (creditCardTransactionsQuantity) {
          for (let i = 0; i < creditCardTransactionsQuantity; i++) {
            newTransactions.push({
              id: null,
              paymentTypeId: transactionPaymentTypes.CREDIT_CARD,
              installments: this.proposal?.installment_quantity || 1,
              amount: floatToMoney(
                parseFloat((this.totalValue / totalQuantity).toFixed(2))
              ),
            });
          }
        }
        this.transactions = newTransactions;
        this.fixTotalTransactionsValue();
        this.preSave();
      }
    },
    fixTotalTransactionsValue() {
      const totalTransactions = _.sumBy(this.transactions, (transaction) =>
        moneyToFloat(transaction.amount)
      );
      if (this.totalValue && totalTransactions) {
        const difference = this.totalValue - totalTransactions;
        if (difference !== 0 && this.transactions.length > 0) {
          const newAmount = parseFloat(
            (moneyToFloat(this.transactions[0].amount) + difference).toFixed(2)
          );
          this.transactions[0].amount = floatToMoney(newAmount);
        }
      }
    },
    changePaymentInstallments(installments) {
      this.transactions.forEach((transaction) => {
        if (installments > 1) {
          transaction.paymentTypeId = transactionPaymentTypes.CREDIT_CARD;
        }
        transaction.installments = installments;
        transaction.amount = floatToMoney(
          parseFloat((this.totalValue / this.transactions.length).toFixed(2))
        );
      });
      this.fixTotalTransactionsValue();
      this.preSave();
    },
  },
};
</script>

<style lang="scss" scoped>
.totalizer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 14px;
  &.red-container {
    background-image: var(--red-gradient);
  }
  &.green-container {
    background-image: var(--green-gradient);
  }
}
</style>
