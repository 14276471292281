<template>
  <form
    class="my-5"
    id="futuro-customer-professional-insurance"
    @submit.prevent
  >
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-professionao-insurance.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Seguro profissional</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mb-3 gy-3">
        <div class="col-md-6 col-12">
          <label for="haveProfessionalInsurance" class="form-label">
            Você tem seguro profissional?
          </label>
          <select
            id="haveProfessionalInsurance"
            v-model="haveProfessionalInsurance"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
        </div>
      </div>
      <template v-if="isInterview">
        <div
          v-if="haveProfessionalInsurance === 'yes' && haveInsurance === 'no'"
          class="row mb-3 gy-3"
        >
          <div class="col-12">
            <label for="becauseProfessionalInsurance" class="form-label">
              Por que possui seguro?
            </label>
            <textarea
              id="becauseProfessionalInsurance"
              v-model="becauseProfessionalInsurance"
              type="text"
              class="form-control"
              rows="4"
              :disabled="isDetail"
              @blur="preSave()"
            />
          </div>
        </div>
        <template
          v-if="haveProfessionalInsurance === 'yes' && haveInsurance === 'no'"
        >
          <div class="row mb-3 gy-3">
            <div class="col-12">
              <label for="insurances" class="form-label">
                Liste abaixo qual(is) a(s) coberturas(s) que você possui
              </label>
              <textarea
                id="insurances"
                v-model="insurances"
                type="text"
                class="form-control"
                :disabled="isDetail"
                rows="4"
                @blur="preSave()"
              />
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-6 col-12">
              <label for="award" class="form-label"> Prêmio </label>
              <input
                id="award"
                v-model.lazy="award"
                v-money="moneyFormat"
                type="text"
                :disabled="isDetail"
                class="form-control"
                :class="{ 'is-invalid': v$.award.$error }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.award.minLength.$invalid">
                  O valor do prêmio é inválido
                </span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label for="roof" class="form-label"> Cobertura </label>
              <input
                id="roof"
                v-model.lazy="roof"
                v-money="moneyFormat"
                :disabled="isDetail"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.roof.$error }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.roof.minLength.$invalid">
                  O valor da cobertura é inválido
                </span>
              </div>
            </div>
          </div>
        </template>
        <template
          v-if="haveProfessionalInsurance === 'yes' && haveInsurance === 'no'"
        >
          <div class="row mb-3 gy-3 align-items-end">
            <div class="col-md-6 col-12">
              <label for="betterInsurance" class="form-label">
                Se por acaso conseguir encontrar algo melhor e mais em conta
                você trocaria?
              </label>
              <select
                id="betterInsurance"
                v-model="betterInsurance"
                :disabled="isDetail"
                class="form-select"
                :class="{ 'select-disabled': isDetail }"
                @blur="preSave()"
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </select>
            </div>
          </div>
        </template>
      </template>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { minLength } from "@vuelidate/validators";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { floatToMoney, booleanToString } from "@/helpers/converters";
import { getProfissionalInsurancePayload } from "@/helpers/interview_payloads";
import * as types from "../store/types";
import Spinner from "@/modules/shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    updateCashFlow: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      haveProfessionalInsurance: "no",
      becauseProfessionalInsurance: "",
      haveInsurance: "no",
      insurances: "",
      award: "",
      roof: "",
      betterInsurance: "no",
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      haveProfessionalInsurance: {},
      becauseProfessionalInsurance: {},
      haveInsurance: {},
      insurances: {},
      award: {
        minLength: minLength(7),
      },
      roof: {
        minLength: minLength(7),
      },
      betterInsurance: {},
    };
  },
  computed: {
    ...mapGetters({
      formatedCurrency: types.FORMATED_CURRENCY,
    }),
    moneyFormat: function () {
      return this.formatedCurrency
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getInterviewProfessionalInsurance: types.GET_PROFESSIONAL_INSURANCE,
      autoSaveProfessionalInsurance: types.AUTO_SAVE_PROFESSIONAL_INSURANCE,
    }),
    ...mapMutations({
      mutateProfessionalInsurance: types.MUTATE_PROFESSIONAL_INSURANCE,
    }),
    async fetchInterviewProfessionalInsurance() {
      this.loading = true;
      try {
        const response = await this.getInterviewProfessionalInsurance(
          this.$route.params.customerId
        );
        const {
          have_professional_insurance,
          because_professional_insurance,
          have_insurance,
          insurances,
          award,
          roof,
          better_insurance,
        } = response.data;
        this.haveProfessionalInsurance = booleanToString(
          have_professional_insurance
        );
        this.becauseProfessionalInsurance = because_professional_insurance;
        this.haveInsurance = booleanToString(have_insurance);
        this.insurances = insurances;
        this.award = floatToMoney(award);
        this.roof = floatToMoney(roof);
        this.betterInsurance = booleanToString(better_insurance);
        const payload = getProfissionalInsurancePayload(this);
        this.mutateProfessionalInsurance(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter as informações sobre seguro de vida do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchInterviewProfessionalInsurance()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    preSave() {
      const payload = getProfissionalInsurancePayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateProfessionalInsurance(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveProfessionalInsurance(payload)
        .then(() => {
          this.updateCashFlow();
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente os dados de seguro de vida do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
