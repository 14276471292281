import * as types from './types'

export default {
  [types.MUTATE_CHECKOUT]: (state, payload) => {
    state.checkoutData = payload
  },
  [types.MUTATE_INTERVIEW_CHECKOUT_STATUS]: (state, payload) => {
    state.checkoutData.interview.status = payload.status
  }
}
