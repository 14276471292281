<template>
  <div
    class="dark bg-with-image main-container pt-0 pb-0"
    style="background-repeat: repeat-y"
  >
    <message-modal />
    <pix-cnpj-modal />
    <div class="container-fluid">
      <div
        v-if="loading"
        class="d-flex flex-column align-items-center justify-content-center p-5"
      >
        <img
          src="~@/assets/img/logo-white.svg"
          height="150"
          alt="Futuro | Consultoria Financeira"
        />
        <span class="mt-4"
          >Estamos obtendo as informações do seu investimento</span
        >
        <span>Aguarde um momento...</span>
        <spinner class="mt-3" size="large" />
      </div>
      <div
        v-else-if="checkoutData"
        class="d-flex flex-column justify-content-center"
      >
        <div class="row mx-md-5 mx-2">
          <img
            src="~@/assets/img/logo-white.svg"
            alt="Futuro | Consultoria Financeira"
            class="logo mt-2 mb-4"
          />
          <div class="col-12">
            <h1 class="page-title-checkout mb-2">
              {{ customerName }}
            </h1>
          </div>
          <div class="col-12">
            <p class="mb-0">
              Você está prestes a concluir o investimento no seu Futuro!
            </p>
            <p>
              Confira os dados abaixo e efetue os pagamentos para concluir o
              processo.
            </p>
          </div>
        </div>
        <div v-if="checkoutData.contractSigned" class="row">
          <div class="col-12 payments-carousel">
            <carousel
              v-if="
                checkoutData &&
                checkoutData.payments &&
                checkoutData.payments.length > 0
              "
              v-model="activeSlide"
              :wrap-around="true"
              :mouse-drag="false"
              :touch-drag="false"
            >
              <template #addons>
                <pagination />
              </template>
              <slide
                v-for="(payment, index) in checkoutData.payments"
                :key="index"
              >
                <div v-if="payment.isSubscription" class="card">
                  <div class="d-flex flex-column">
                    <div class="card-title col-auto mb-3">
                      <img
                        src="~@/assets/img/ic-star.svg"
                        alt="Futuro | Consultoria Financeira"
                      />
                      <h2>Assinatura</h2>
                    </div>
                    <div class="row">
                      <p class="mb-0">
                        Forma de pagamento:
                        <strong>
                          {{ $filters.paymentType(payment.paymentMethod) }}
                        </strong>
                      </p>
                      <p class="mb-0">
                        Valor da mensalidade:
                        <strong
                          >{{ formatCurrency(payment.parcelAmount) }}
                        </strong>
                      </p>
                    </div>
                    <div
                      v-if="payment.status !== 'paid' && !subscription.paid"
                      class="row justify-content-center mt-3"
                    >
                      <credit-card
                        :customerName="customerName"
                        :isCustomerDomestic="isCustomerDomestic"
                        :loading="subscription.paying"
                        buttonTitle="Pagar assinatura"
                        :publicKey="checkoutData.publicKey"
                        @pay="
                          (creditCardData) =>
                            paySubscriptionUsingCreditCard(
                              payment,
                              creditCardData
                            )
                        "
                      />
                    </div>
                    <div
                      v-if="payment.status === 'refused'"
                      class="row justify-content-center mt-3 error-container"
                    >
                      <span class="col-12"
                        >Ooops! Ocorreu um erro com o seu pagamento</span
                      >
                      <span class="col-12"
                        >Entre em contato com o seu consultor</span
                      >
                    </div>
                    <div
                      v-if="payment.status === 'refund'"
                      class="row justify-content-center mt-3 error-container"
                    >
                      <span class="col-12"
                        >Ooops! Seu pagamento foi estornado</span
                      >
                      <span class="col-12">Realize um novo pagamento</span>
                    </div>
                    <div
                      v-if="payment.status === 'paid' || subscription.paid"
                      class="row justify-content-center mt-3 paid-container"
                    >
                      <span>Pagamento realizado</span>
                    </div>
                  </div>
                </div>
                <div v-else class="card">
                  <div class="d-flex flex-column">
                    <div class="card-title col-auto mb-3">
                      <img
                        src="~@/assets/img/ic-project.svg"
                        alt="Futuro | Consultoria Financeira"
                      />
                      <h2>Projeto financeiro</h2>
                    </div>
                    <div class="row">
                      <p class="mb-0">
                        Forma de pagamento:
                        <strong>
                          {{ $filters.paymentType(payment.paymentMethod) }}
                        </strong>
                      </p>
                      <p class="mb-0">
                        <template
                          v-if="payment.paymentMethod !== 'credit_card'"
                        >
                          Valor:
                          <strong>
                            {{ formatCurrency(payment.amount) }}
                          </strong>
                        </template>
                        <template v-else>
                          Valor:
                          <strong
                            >{{ payment.installments }}x de
                            {{ formatCurrency(payment.parcelAmount) }}
                          </strong>
                        </template>
                      </p>
                    </div>
                    <template
                      v-if="
                        payment.status !== 'paid' &&
                        payment.status !== 'processing'
                      "
                    >
                      <div
                        v-if="payment.isPix"
                        class="row justify-content-center my-3"
                      >
                        <div class="col-auto">
                          <qrcode-vue
                            :value="payment.pixCode"
                            :size="180"
                            level="H"
                            render-as="svg"
                            :margin="3"
                          />
                        </div>
                      </div>
                      <div class="row justify-content-center mt-3">
                        <template
                          v-if="payment.paymentMethod === 'credit_card'"
                        >
                          <credit-card
                            :customerName="customerName"
                            :isCustomerDomestic="isCustomerDomestic"
                            :loading="interview.paying"
                            buttonTitle="Pagar projeto financeiro"
                            :publicKey="checkoutData.publicKey"
                            @pay="
                              (creditCardData) =>
                                payInterviewUsingCreditCard(
                                  payment,
                                  creditCardData
                                )
                            "
                          />
                        </template>
                        <template v-else-if="payment.isPix">
                          <button
                            class="btn btn-orange col-md-6 col-12"
                            @click.prevent="
                              () => copyPixCodeToClipboard(payment)
                            "
                          >
                            Copiar código pix
                          </button>
                          <p class="my-3 text-center">
                            Após realizar o pagamento, você pode atualizar esta
                            página e conferir se o investimento no projeto
                            financeiro foi realizado
                          </p>
                          <button
                            class="btn btn-outline-light col-md-8 col-12"
                            @click.prevent="() => openPixCnpjModal(payment)"
                          >
                            Não consegui realizar o pagamento
                          </button>
                        </template>
                        <template v-else>
                          <p class="mb-0">
                            <strong>Dados bancários:</strong>
                          </p>
                          <p class="my-0">
                            Nome: Futuro Gestão Financeira Pessoal II Ltda
                          </p>
                          <p class="my-0">CNPJ: 48.312.560/0001-08</p>
                          <p class="my-0">
                            Instituição: 197 - Stone Pagamentos S.A.
                          </p>
                          <p class="my-0">Agência: 0001</p>
                          <p class="my-0">Conta: 3221265-6</p>
                          <p class="mt-3 mb-0">
                            <strong
                              >Ao concluir a transferência, envie o comprovante
                              para o seu consultor para que o pagamento seja
                              registrado.</strong
                            >
                          </p>
                        </template>
                      </div>
                    </template>
                    <div
                      v-if="payment.status === 'refused'"
                      class="row justify-content-center mt-3 error-container"
                    >
                      <span class="col-12"
                        >Ooops! Ocorreu um erro com o seu pagamento</span
                      >
                      <span class="col-12"
                        >Aguarde 10 minutos e tente novamente</span
                      >
                      <span class="col-12"
                        >Fale com seu consultor se o erro persistir</span
                      >
                    </div>
                    <div
                      v-if="payment.status === 'refund'"
                      class="row justify-content-center mt-3 error-container"
                    >
                      <span class="col-12"
                        >Ooops! Seu pagamento foi estornado</span
                      >
                      <span class="col-12">Realize um novo pagamento</span>
                    </div>
                    <div
                      v-if="payment.status === 'paid'"
                      class="row justify-content-center mt-3 paid-container"
                    >
                      <span>Pagamento realizado</span>
                    </div>
                    <div
                      v-if="payment.status === 'processing'"
                      class="row justify-content-center mt-3 processing-container"
                    >
                      <span>Em processamento. Aguarde...</span>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
        <div v-else class="row justify-content-center mx-md-5 mx-2">
          <p>
            <strong
              >Você recebeu um e-mail para assinatura do contrato, mas
              verificamos que ele ainda não foi assinado.</strong
            >
          </p>
          <p class="mb-0">
            Para prosseguir com o pagamento, você deve primeiro assinar o
            contrato e depois atualizar esta página.
          </p>
          <p>
            Caso não tenha encontrado o e-mail para assinatura do contrato, você
            pode solicitar abaixo o re-envio do email com as instruções de
            assinatura assinatura:
          </p>
          <button
            class="btn btn-red col-md-4 col-8 mb-4"
            :disabled="resending"
            @click.prevent="resendEmail"
          >
            {{
              resending
                ? "Aguarde..."
                : "Re-enviar e-mail de assinatura do contrato"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import * as sharedTypes from "@/modules/shared/store/types";
import Spinner from "@/modules/shared/components/Spinner";
import MessageModal from "@/modules/shared/components/MessageModal";
import * as types from "../store/types";
import * as interviewTypes from "@/modules/interview/store/types";
import { getPercentageFormat } from "@/helpers/formatting";
import QrcodeVue from "qrcode.vue";
import PixCnpjModal from "../components/PixCnpjModal";
import CreditCard from "../components/CreditCard";
export default {
  components: {
    MessageModal,
    Spinner,
    QrcodeVue,
    PixCnpjModal,
    CreditCard,
    Spinner,
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      loading: true,
      resending: false,
      activeSlide: 0,
      interview: {
        paying: false,
        clientOwnsTheCard: "no",
      },
      subscription: {
        paying: false,
        paid: false,
        clientOwnsTheCard: "no",
      },
    };
  },
  computed: {
    ...mapGetters({
      checkoutData: types.CHECKOUT,
      formatCurrency: interviewTypes.FORMAT_CURRENCY,
      formatedCurrency: interviewTypes.FORMATED_CURRENCY,
    }),
    moneyFormat: function () {
      return this.formatedCurrency;
    },
    percentageFormat: function () {
      return getPercentageFormat();
    },
    customerName: function () {
      return this.checkoutData?.customer?.name || "";
    },
    isCustomerDomestic: function () {
      return this.checkoutData?.customer?.country === "br";
    },
  },
  mounted() {
    this.loading = true;
    this.setNoOptionsHeaderType();
    this.getCheckoutData(this.$route.params.token)
      .then((response) => {
        if (response.data?.payments?.length) {
          for (let i = 0; i < response.data.payments.length; i++) {
            if (response.data.payments[i].status !== "paid") {
              this.activeSlide = i;
              break;
            }
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 404) {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Seu contrato expirou.",
              "Por gentileza, informe o seu consultor sobre o ocorrido.",
            ],
          });
        } else {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível obter informações sobre o seu investimento.",
              "Por gentileza, informe o seu consultor sobre o ocorrido.",
            ],
          });
        }
      })
      .finally(() => {
        if (this.checkoutData?.customer?.country === "br") {
          this.interview.clientOwnsTheCard = "yes";
          this.subscription.clientOwnsTheCard = "yes";
        }
        this.loading = false;
      });
  },
  methods: {
    ...mapActions({
      setNoOptionsHeaderType: sharedTypes.SET_NO_OPTIONS_HEADER_TYPE,
      getCheckoutData: types.GET_CHECKOUT,
      resend: types.RESEND_SIGN_EMAIL,
      paySubscription: types.PAY_SUBSCRIPTION,
      payInterview: types.PAY_INTERVIEW,
      getCheckoutStatus: types.GET_CHECKOUT_STATUS,
    }),
    payInterviewUsingCreditCard(payment, creditCardData) {
      this.interview.paying = true;
      this.payInterview({
        customer_token: this.$route.params.token,
        contract_payment_transaction_id: payment.contractPaymentTransactionId,
        ...creditCardData,
      })
        .then((response) => {
          this.updateCheckoutStatus(payment);
        })
        .catch((error) => {
          this.interview.paying = false;
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "O pagamento do projeto financeiro não foi realizado, pois ocorreu um erro durante a transação.",
              "Solicitamos que aguarde 10 minutos e tente novamente.",
              "Caso o erro persista, informe o seu consultor sobre o ocorrido.",
            ],
          });
        });
    },
    paySubscriptionUsingCreditCard(payment, creditCardData) {
      this.subscription.paying = true;
      this.paySubscription({
        customer_token: this.$route.params.token,
        contract_id: payment.contractId,
        ...creditCardData,
      })
        .then((response) => {
          this.subscription.paid = true;
          this.$vfm.show("message", {
            type: "success",
            messages: ["O pagamento da assinatura foi realizado com sucesso!"],
          });
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "O pagamento da assinatura não foi realizado, pois ocorreu um erro durante a transação.",
              "Solicitamos que aguarde 10 minutos e tente novamente.",
              "Caso o erro persista, informe o seu consultor sobre o ocorrido.",
            ],
          });
        })
        .finally(() => {
          this.subscription.paying = false;
        });
    },
    updateCheckoutStatus(payment) {
      setTimeout(() => {
        this.getCheckoutStatus(payment.contractPaymentTransactionId)
          .then((response) => {
            payment.status = response?.data?.status;
            if (
              payment.status !== "processing" &&
              payment.status !== "pending" &&
              payment.status !== "waiting_payment"
            ) {
              this.interview.paying = false;
              if (payment.status === "refused") {
                this.$vfm.show("message", {
                  type: "error",
                  messages: [
                    "O pagamento do projeto financeiro não foi realizado, pois ocorreu um erro durante a transação.",
                    "Solicitamos que aguarde 10 minutos e tente novamente.",
                    "Caso o erro persista, informe o seu consultor sobre o ocorrido.",
                  ],
                });
              }
            } else {
              this.updateCheckoutStatus(payment);
            }
          })
          .catch((error) => {
            this.interview.paying = false;
            this.$vfm.show("message", {
              type: "error",
              messages: [
                "O pagamento do projeto financeiro não foi realizado, pois ocorreu um erro durante a transação.",
                "Solicitamos que aguarde 10 minutos e tente novamente.",
                "Caso o erro persista, informe o seu consultor sobre o ocorrido.",
              ],
            });
          });
      }, 2000);
    },
    copyPixCodeToClipboard(payment) {
      const codePix = payment.pixCode;
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      clipboardData.writeText(codePix);
      this.$vfm.show("message", {
        type: "success",
        messages: ["O código Pix foi copiado para a área de transferência!"],
      });
    },
    openPixCnpjModal(payment) {
      this.$vfm.show("pix-cnpj", {
        amount: payment.amount,
      });
    },
    resendEmail() {
      this.resending = true;
      this.resend(this.$route.params.token)
        .then((response) => {
          this.$vfm.show("message", {
            type: "success",
            messages: ["E-mail enviado com sucesso!"],
          });
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível re-enviar o e-mail para assinatura.",
              "Por gentileza, informe o seu consultor sobre o ocorrido.",
            ],
          });
        })
        .finally(() => {
          this.resending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.paid-container {
  background-image: var(--green-gradient);
  border-radius: 14px;
  padding: 15px;
  text-align: center;
}
.processing-container {
  background-image: var(--blue-gradient);
  border-radius: 14px;
  padding: 15px;
  text-align: center;
}
.error-container {
  background-image: var(--red-gradient);
  border-radius: 14px;
  padding: 15px;
  text-align: center;
}
strong {
  font-weight: bold;
}
.payments-carousel {
  .carousel {
    text-align: left;
  }
  .carousel__slide {
    align-items: start;
  }
  .carousel__pagination {
    margin-left: 0;
    padding-left: 0;
  }
}
</style>
