<template>
  <form class="my-5" id="futuro-customer-insurance" @submit.prevent>
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-globe.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Seguro de vida</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mb-3 gy-3">
        <div class="col-md-6 col-12">
          <label for="haveLifeInsurance" class="form-label">
            Você tem seguro de vida?
          </label>
          <select
            id="haveLifeInsurance"
            v-model="haveLifeInsurance"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
        </div>
      </div>
      <template v-if="isInterview">
        <div
          v-if="haveLifeInsurance === 'no'"
          class="row mb-3 gy-3 align-items-end"
        >
          <div class="col-md-6 col-12">
            <label for="capitalGuarantee" class="form-label">
              Tem patrimônio para te garantir ou garantir sustento dos seu(s)
              dependente(s)?
            </label>
            <select
              id="capitalGuarantee"
              v-model="capitalGuarantee"
              class="form-select"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
          <div class="col-md-6 col-12">
            <label for="financialReserve" class="form-label">
              Caso você fique impossibilitado de trabalhar, você tem uma reserva
              financeira que te garanta o seu sustento para o resto da sua vida?
            </label>
            <select
              id="financialReserve"
              v-model="financialReserve"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              :disabled="isDetail"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
        </div>
        <div
          v-else-if="haveLifeInsurance === 'yes' && haveInsurance === 'no'"
          class="row mb-3 gy-3"
        >
          <div class="col-12">
            <label for="becauseLifeInsurance" class="form-label">
              Por que possui seguro?
            </label>
            <textarea
              id="becauseLifeInsurance"
              v-model="becauseLifeInsurance"
              type="text"
              class="form-control"
              rows="4"
              :disabled="isDetail"
              @blur="preSave()"
            />
          </div>
        </div>
        <template v-if="haveLifeInsurance === 'yes' && haveInsurance === 'no'">
          <div class="row mb-3 gy-3">
            <div class="col-12">
              <label for="insurances" class="form-label">
                Liste abaixo qual(is) a(s) coberturas(s) que você possui
              </label>
              <textarea
                id="insurances"
                v-model="insurances"
                type="text"
                class="form-control"
                :disabled="isDetail"
                rows="4"
                @blur="preSave()"
              />
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-6 col-12">
              <label for="award" class="form-label"> Prêmio </label>
              <input
                id="award"
                v-model.lazy="award"
                v-money="moneyFormat"
                type="text"
                :disabled="isDetail"
                class="form-control"
                :class="{ 'is-invalid': v$.award.$error }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.award.minLength.$invalid">
                  O valor do prêmio é inválido
                </span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label for="roof" class="form-label"> Cobertura </label>
              <input
                id="roof"
                v-model.lazy="roof"
                v-money="moneyFormat"
                :disabled="isDetail"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.roof.$error }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.roof.minLength.$invalid">
                  O valor da cobertura é inválido
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-if="haveLifeInsurance === 'yes' && haveInsurance === 'no'">
          <div class="row mb-3 gy-3 align-items-end">
            <div class="col-md-6 col-12">
              <label for="betterInsurance" class="form-label">
                Se por acaso conseguir encontrar algo melhor e mais em conta
                você trocaria?
              </label>
              <select
                id="betterInsurance"
                v-model="betterInsurance"
                :disabled="isDetail"
                class="form-select"
                :class="{ 'select-disabled': isDetail }"
                @blur="preSave()"
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </select>
            </div>
          </div>
        </template>
      </template>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { minLength } from "@vuelidate/validators";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { floatToMoney, booleanToString } from "@/helpers/converters";
import { getLifeInsurancePayload } from "@/helpers/interview_payloads";
import * as types from "../store/types";
import Spinner from "@/modules/shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    updateCashFlow: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      haveLifeInsurance: "no",
      becauseLifeInsurance: "",
      haveInsurance: "no",
      insurances: "",
      award: "",
      roof: "",
      betterInsurance: "no",
      capitalGuarantee: "no",
      financialReserve: "no",
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      haveLifeInsurance: {},
      becauseLifeInsurance: {},
      haveInsurance: {},
      insurances: {},
      award: {
        minLength: minLength(7),
      },
      roof: {
        minLength: minLength(7),
      },
      betterInsurance: {},
      capitalGuarantee: {},
      financialReserve: {},
    };
  },
  computed: {
    ...mapGetters({
      formatedCurrency: types.FORMATED_CURRENCY,
    }),
    moneyFormat: function () {
      return this.formatedCurrency
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getInterviewLifeInsurance: types.GET_LIFE_INSURANCE,
      autoSaveLiveInsurance: types.AUTO_SAVE_LIFE_INSURANCE,
    }),
    ...mapMutations({
      mutateLifeInsurance: types.MUTATE_LIFE_INSURANCE,
    }),
    async fetchInterviewLifeInsurance() {
      this.loading = true;
      try {
        const response = await this.getInterviewLifeInsurance(
          this.$route.params.customerId
        );
        const {
          have_life_insurance,
          because_life_insurance,
          have_insurance,
          insurances,
          award,
          roof,
          better_insurance,
          capital_guarantee,
          financial_reserve,
        } = response.data;
        this.haveLifeInsurance = booleanToString(have_life_insurance);
        this.becauseLifeInsurance = because_life_insurance;
        this.haveInsurance = booleanToString(have_insurance);
        this.insurances = insurances;
        this.award = floatToMoney(award);
        this.roof = floatToMoney(roof);
        this.betterInsurance = booleanToString(better_insurance);
        this.capitalGuarantee = booleanToString(capital_guarantee);
        this.financialReserve = booleanToString(financial_reserve);
        const payload = getLifeInsurancePayload(this);
        this.mutateLifeInsurance(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter as informações sobre seguro de vida do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchInterviewLifeInsurance()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    preSave() {
      const payload = getLifeInsurancePayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateLifeInsurance(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveLiveInsurance(payload)
        .then(() => {
          this.updateCashFlow();
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente os dados de seguro de vida do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
