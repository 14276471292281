<template>
  <confirm-modal />
  <form v-if="isInterview" @submit.prevent>
    <div class="card my-5">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            v-if="!paymentForInterview"
            src="~@/assets/img/chevron-down-solid.svg"
            alt="Futuro | Consultoria Financeira"
            @click="paymentForInterview = !paymentForInterview"
          />
          <img
            v-if="paymentForInterview"
            src="~@/assets/img/chevron-up-solid.svg"
            alt="Futuro | Consultoria Financeira"
            @click="paymentForInterview = !paymentForInterview"
          />
          <h2>Forma de pagamento</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <template v-if="paymentForInterview">
        <div
          v-if="
            calculation &&
            calculation.parcels_values &&
            calculation.subscription_amount &&
            paymentForInterview &&
            !loading
          "
          class="row justify-content-center my-5"
        >
          <div
            class="col-auto m-3 blue-card"
            :class="{ selected: parcelsQuantity === 1, clickable: !isDetail }"
            @click="changeParcelsQuantity(1)"
          >
            <div class="h-100 inner-section">
              <div class="ribbon">
                <span class="ribbon__content"> MAIS ESCOLHIDO </span>
              </div>
              <h1 class="mb-3">Investimento à vista</h1>
              <p class="mb-3">
                <b>{{
                  formatCurrency(
                    calculation.parcels_values["0"].total_amount,
                    "BRL"
                  )
                }}</b>
                <br />
                <b
                  >+{{
                    formatCurrency(calculation.subscription_amount, "BRL")
                  }}</b
                >
                mensais
              </p>
              <p class="final-value">
                {{
                  formatCurrency(
                    calculation.parcels_values["0"].discount,
                    "BRL"
                  )
                }}
                <br />
                desconto total
              </p>
            </div>
          </div>
          <div
            class="col-auto m-3 blue-card"
            :class="{ selected: parcelsQuantity > 1, clickable: !isDetail }"
            @click="changeParcelsQuantity(selectedParcel)"
          >
            <div class="h-100 inner-section">
              <h1>Investimento parcelado</h1>
              <div class="w-100 px-3">
                <select
                  v-model="selectedParcel"
                  :disabled="isDetail"
                  class="form-select"
                  :class="{ 'select-disabled': isDetail }"
                  @change="onChangeParcels($event)"
                >
                  <option
                    v-for="parcel in parcels"
                    :key="parcel"
                    :value="parcel"
                  >
                    {{ parcel }} vezes
                  </option>
                </select>
              </div>
              <p>
                {{
                  formatCurrency(
                    calculation.parcels_values[(selectedParcel - 1).toString()]
                      .installment_amount,
                    "BRL"
                  )
                }}
                por {{ selectedParcel }} meses +
                {{ formatCurrency(calculation.subscription_amount, "BRL") }}
                mensais
              </p>
              <p
                v-if="
                  calculation.parcels_values[(selectedParcel - 1).toString()]
                    .discount > 0
                "
                class="final-value"
              >
                {{
                  formatCurrency(
                    calculation.parcels_values[(selectedParcel - 1).toString()]
                      .discount,
                    "BRL"
                  )
                }}
                <br />
                desconto total
              </p>
            </div>
          </div>
        </div>
        <div v-if="!loading" class="row">
          <div class="col-12">
            <button
              :disabled="loading"
              class="btn btn-outline-light col-auto mx-2 exit"
              @click.prevent="discountsOpened = !discountsOpened"
            >
              <img
                v-if="!discountsOpened"
                src="~@/assets/img/chevron-down-solid.svg"
                alt=""
                height="15"
                class="me-2"
              />
              <img
                v-if="discountsOpened"
                src="~@/assets/img/chevron-up-solid.svg"
                alt=""
                height="15"
                class="me-2"
              />
              {{ discountsOpened ? "Ocultar" : "Ver" }} opções adicionais
            </button>
          </div>
          <div v-if="discountsOpened" class="col-12">
            <div class="row mt-3">
              <div class="col-md-4 col-12">
                <label for="investmentPercentage" class="form-label">
                  Acréscimo com base no patrimônio líquido
                </label>
                <input
                  id="investmentPercentage"
                  v-model.lazy="investmentPercentage"
                  v-money="percentageFormat"
                  type="text"
                  class="form-control"
                  :disabled="isDetail"
                  :class="{ 'is-invalid': v$.investmentPercentage.$error }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.investmentPercentage.minLength.$invalid">
                    O valor é inválido
                  </span>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <label for="discountPercentage" class="form-label">
                  Desconto sob valor do projeto financeiro
                </label>
                <input
                  id="discountPercentage"
                  v-model.lazy="discountPercentage"
                  v-money="percentageFormat"
                  type="text"
                  class="form-control"
                  :disabled="isDetail"
                  :class="{ 'is-invalid': v$.discountPercentage.$error }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.discountPercentage.minLength.$invalid">
                    O valor é inválido
                  </span>
                  <span v-if="v$.discountPercentage.outOfBounds.$invalid">
                    O valor de desconto deve ser até 30%
                  </span>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <label for="additionPercentage" class="form-label">
                  Acréscimo sob valor do projeto financeiro
                </label>
                <input
                  id="additionPercentage"
                  v-model.lazy="additionPercentage"
                  v-money="percentageFormat"
                  type="text"
                  class="form-control"
                  :disabled="isDetail"
                  :class="{ 'is-invalid': v$.additionPercentage.$error }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.additionPercentage.minLength.$invalid">
                    O valor é inválido
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="card my-5">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            v-if="!subscriptionPlansVisible"
            src="~@/assets/img/chevron-down-solid.svg"
            alt="Futuro | Consultoria Financeira"
            @click="subscriptionPlansVisible = !subscriptionPlansVisible"
          />
          <img
            v-if="subscriptionPlansVisible"
            src="~@/assets/img/chevron-up-solid.svg"
            alt="Futuro | Consultoria Financeira"
            @click="subscriptionPlansVisible = !subscriptionPlansVisible"
          />
          <h2>Pacote de acompanhamento</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div
        v-if="!loading && subscriptionPlansVisible"
        class="row justify-content-around gy-3"
      >
        <section class="container-plans">
          <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="10"
            :pagination="{ clickable: true }"
            :breakpoints="breakpoints"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <template v-for="plan in subscriptionPlans" :key="plan.id">
              <swiper-slide
                v-if="customerPlans.includes(plan.id)"
                :id="plan.id"
                @click="setSubscription(plan)"
              >
                <article
                  class="card-plans"
                  :class="{
                    'choice-border': subscriptionPlanId === plan.id,
                    'card-best-choice': plan.most_chosen,
                    clickable: !isDetail,
                  }"
                >
                  <div v-if="plan.most_chosen" class="card-head-best-choice">
                    <h5>Mais escolhido</h5>
                    <div class="bg-star">
                      <img
                        src="@/assets/img/star-best-choice.svg"
                        alt="mais-escolhido"
                      />
                    </div>
                  </div>
                  <div class="card-title">
                    <h3 class="card-font">{{ plan.name }}</h3>
                  </div>
                  <div class="card-price">
                    <h1 class="card-font">
                      {{ formatCurrency(plan.amount, "BRL") }}
                      <p>/mês</p>
                    </h1>
                  </div>
                  <div
                    :set="
                      (planMetadata = JSON.parse(
                        plan.financial_solution.metadata
                      ))
                    "
                    class="card-description"
                  >
                    <div class="card-detail">
                      <img src="@/assets/img/correct.svg" alt="incluso" />
                      <span>Suporte ilimitado pelo Whatsapp</span>
                    </div>
                    <div
                      class="card-detail"
                      :class="{
                        'not-included': !planMetadata.ead_access,
                      }"
                    >
                      <img
                        v-if="planMetadata.ead_access"
                        src="@/assets/img/correct.svg"
                        alt="Incluso"
                      />
                      <img
                        v-else
                        src="@/assets/img/wrong.svg"
                        alt="Não incluso"
                      />
                      <span>Escola de negócios</span>
                    </div>
                    <div
                      class="card-detail"
                      :class="{
                        'not-included': !planMetadata.workshops,
                      }"
                    >
                      <img
                        v-if="planMetadata.workshops"
                        src="@/assets/img/correct.svg"
                        alt="Incluso"
                      />
                      <img
                        v-else
                        src="@/assets/img/wrong.svg"
                        alt="Não incluso"
                      />
                      <span>Workshop ao vivo</span>
                    </div>
                    <div
                      class="card-detail"
                      :class="{
                        'not-included': !planMetadata.benefits_club,
                      }"
                    >
                      <img
                        v-if="planMetadata.benefits_club"
                        src="@/assets/img/correct.svg"
                        alt="Incluso"
                      />
                      <img
                        v-else
                        src="@/assets/img/wrong.svg"
                        alt="Não incluso"
                      />
                      <span>Clube de descontos</span>
                    </div>
                    <div
                      class="card-detail"
                      :class="{
                        'not-included': !planMetadata.app,
                      }"
                    >
                      <img
                        v-if="planMetadata.app"
                        src="@/assets/img/correct.svg"
                        alt="Incluso"
                      />
                      <img
                        v-else
                        src="@/assets/img/wrong.svg"
                        alt="Não incluso"
                      />
                      <span>{{ planMetadata.app }}</span>
                    </div>
                    <div
                      class="card-detail"
                      :class="{
                        'not-included': !planMetadata.meetings,
                      }"
                    >
                      <template v-if="planMetadata.meetings">
                        <img src="@/assets/img/correct.svg" alt="Incluso" />
                        <span v-html="planMetadata.meetings"></span>
                      </template>
                      <template v-else>
                        <img src="@/assets/img/wrong.svg" alt="Não incluso" />
                        <span>Não existem reuniões neste plano</span>
                      </template>
                    </div>
                  </div>
                </article>
              </swiper-slide>
            </template>
          </swiper>
        </section>
      </div>
    </div>
    <div class="card my-5" :class="{ 'error-bg': v$.recommendations.$invalid }">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-like.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Transforme futuros e ganhe</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mt-5 px-3">
        <div class="col-md-3 col-6 text-center d-flex flex-column">
          <span class="mb-2"> 0 a 4 = 0% </span>
          <div
            class="recommendation-discount-value"
            :class="{
              selected: recommendationDiscount === 0,
              clickable:
                !isDetail ||
                (calculation &&
                  calculation.discount_value_0 &&
                  calculation &&
                  calculation.discount_value_0.active),
            }"
            @click="setRecommendationDiscount(0, true)"
          >
            +
            {{
              formatCurrency(
                calculation && calculation.discount_value_0
                  ? calculation.discount_value_0.discount
                  : 0,
                "BRL"
              )
            }}
          </div>
        </div>
        <div class="col-md-3 col-6 text-center d-flex flex-column">
          <span class="mb-2"> 5 a 9 = 5% </span>
          <div
            class="recommendation-discount-value"
            :class="{
              selected: recommendationDiscount === 5,
              clickable:
                !isDetail ||
                (calculation &&
                  calculation.discount_value_5 &&
                  calculation &&
                  calculation.discount_value_5.active),
            }"
            @click="setRecommendationDiscount(5, true)"
          >
            +
            {{
              formatCurrency(
                calculation && calculation.discount_value_5
                  ? calculation.discount_value_5.discount
                  : 0,
                "BRL"
              )
            }}
          </div>
        </div>
        <div class="col-md-3 col-6 text-center d-flex flex-column">
          <span class="mb-2"> 10 a 14 = 10% </span>
          <div
            class="recommendation-discount-value"
            :class="{
              selected: recommendationDiscount === 10,
              clickable:
                !isDetail ||
                (calculation &&
                  calculation.discount_value_10 &&
                  calculation &&
                  calculation.discount_value_10.active),
            }"
            @click="setRecommendationDiscount(10, true)"
          >
            +
            {{
              formatCurrency(
                calculation && calculation.discount_value_10
                  ? calculation.discount_value_10.discount
                  : 0,
                "BRL"
              )
            }}
          </div>
        </div>
        <div class="col-md-3 col-6 text-center d-flex flex-column">
          <span class="mb-2"> acima de 15 = 15% </span>
          <div
            class="recommendation-discount-value"
            :class="{
              selected: recommendationDiscount === 15,
              clickable:
                !isDetail ||
                (calculation &&
                  calculation.discount_value_15 &&
                  calculation &&
                  calculation.discount_value_15.active),
            }"
            @click="setRecommendationDiscount(15, true)"
          >
            +
            {{
              formatCurrency(
                calculation && calculation.discount_value_15
                  ? calculation.discount_value_15.discount
                  : 0,
                "BRL"
              )
            }}
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div id="recommendationsSection" class="col-12">
          <h2>Recomendações</h2>
        </div>
      </div>
      <div
        v-for="(recommendation, index) in recommendations"
        :key="`recommendation-${index}`"
        class="inner-card mt-3"
        style="padding: 20px"
      >
        <div class="row mb-2">
          <div class="col-12">
            <h2>
              <span style="float: left">#{{ index + 1 }}</span>
              <span style="float: right">{{
                $filters.leadType(recommendation.type)
              }}</span>
            </h2>
          </div>
        </div>
        <div class="row justify-content-around">
          <div class="col-md-2 col-12 mb-3 mb-md-0">
            <label :for="`recommendationName-${index}`" class="form-label">
              Nome
            </label>
            <input
              :id="`recommendationName-${index}`"
              v-model="recommendation.name"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{
                'is-invalid':
                  v$.recommendations.$dirty &&
                  v$.recommendations.$each.$response.$errors[index].name.length,
              }"
              @focusout="loadingProposal = true"
              @blur="autoSaveRecommendations()"
            />

            <div class="invalid-feedback">
              <span
                v-if="
                  v$.recommendations.$dirty &&
                  v$.recommendations.$each.$response.$errors[index].name.length
                "
              >
                Nome inválido
              </span>
            </div>
          </div>
          <div class="col-md-2 col-12 mb-3 mb-md-0">
            <label :for="`recommendationCellPhone-${index}`" class="form-label">
              Celular
            </label>
            <input
              :id="`recommendationCellPhone-${index}`"
              v-model="recommendation.cellPhone"
              v-mask="'+## ## # #### ####'"
              type="text"
              :disabled="isDetail || loadingProposal"
              class="form-control"
              :class="{
                'is-invalid':
                  (v$.recommendations.$dirty &&
                    v$.recommendations.$each.$response.$errors[index].cellPhone
                      .length) ||
                  cellPhoneWithError[index],
              }"
              @input="checkCellPhone(recommendation, index)"
              @blur="autoSaveRecommendations()"
            />

            <div class="invalid-feedback">
              <span
                v-if="
                  v$.recommendations.$dirty &&
                  v$.recommendations.$each.$response.$errors[index].cellPhone
                    .length
                "
              >
                Celular inválido
              </span>
              <span v-if="cellPhoneWithError[index]">Celular já existe</span>
            </div>
          </div>
          <div class="col-md-2 col-12 mb-3 mb-md-0">
            <label
              :for="`recommendationOccupation-${index}`"
              class="form-label"
            >
              Profissão
            </label>
            <input
              :id="`recommendationOccupation-${index}`"
              v-model="recommendation.occupation"
              type="text"
              :disabled="isDetail || loadingProposal"
              class="form-control"
              @blur="autoSaveRecommendations()"
            />
          </div>
          <div class="col-md-2 col-12 mb-3 mb-md-0">
            <label :for="`recommendationGoal-${index}`" class="form-label">
              Objetivo
            </label>
            <select
              :id="`recommendationGoal-${index}`"
              v-model="recommendation.goal"
              :disabled="isDetail || loadingProposal"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              @blur="autoSaveRecommendations()"
            >
              <option
                v-for="goal_key in Object.keys(goals)"
                :key="goal_key"
                :value="goal_key"
              >
                {{ goals[goal_key] }}
              </option>
            </select>
          </div>
          <div class="col-md-2 col-12 mb-3 mb-md-0">
            <label :for="`recommendationComment-${index}`" class="form-label">
              Observação
            </label>
            <input
              :id="`recommendationComment-${index}`"
              v-model="recommendation.comment"
              type="text"
              :disabled="isDetail || loadingProposal"
              class="form-control"
              @blur="autoSaveRecommendations()"
            />
          </div>
          <div class="col-auto d-flex align-items-center">
            <template v-if="!isDetail">
              <div class="d-flex flex-row">
                <button
                  v-if="recommendation.id === ''"
                  class="btn btn-red margin-button"
                  :disabled="loadingProposal"
                  @click.prevent="removeRecWhenFieldsEmpty(index)"
                >
                  Excluir
                </button>
                <button
                  v-else
                  class="btn btn-red margin-button"
                  :disabled="loadingProposal"
                  @click.prevent="openConfimationModal(recommendation)"
                >
                  Excluir
                </button>
              </div>
            </template>
          </div>
          <div
            :class="'col-12 mt-3 d-flex justify-content-start align-items-center'"
            :style="!loadingRecs ? 'margin: 30px;' : ''"
          >
            <spinner
              v-if="loadingRecs"
              size="small"
              style="margin-right: 0.25rem"
            />
            <span>{{ loadingRecs ? "Registrando informações..." : "" }}</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center px-4 mt-4 justify-content-between">
        <template v-if="!isDetail">
          <div class="d-flex justify-content-between p-0 column-mobile">
            <div class="d-flex">
              <button
                class="btn btn-orange col-auto mx-1"
                :class="{ 'btn-white-full': v$.recommendations.$invalid }"
                :disabled="loadingProposal"
                @click.prevent="addRecommendation('PCL')"
              >
                Adicionar rec. cliente
              </button>
              <button
                class="btn btn-orange col-auto mx-1"
                :class="{ 'btn-white-full': v$.recommendations.$invalid }"
                :disabled="loadingProposal"
                @click.prevent="addRecommendation('PPN')"
              >
                Adicionar rec. PN
              </button>
            </div>

            <button
              class="btn btn-orange col-auto mb-2 mb-md-0"
              :disabled="loadingProposal"
              @click.prevent="saveRecommendation"
            >
              {{ loadingProposal ? "Salvando..." : "Salvar propostas" }}
            </button>
          </div>
        </template>
      </div>
      <div class="row mt-5 mb-3">
        <div class="col-12">
          <h2>Exclusivo para o PN</h2>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <div class="col-md-6 col-12">
          <label for="servicesToContract" class="form-label">
            Serviços a contratar
          </label>
          <select
            id="servicesToContract"
            v-model="servicesToContract"
            :disabled="isDetail"
            class="form-select"
            :class="{
              'is-invalid': v$.servicesToContract.$error,
              'select-disabled': isDetail,
            }"
            @blur="preSave()"
          >
            <option
              v-for="key in Object.keys(contractServiceTypes)"
              :key="key"
              :value="key"
              :disabled="isServicesToContractOptionDisabled(key)"
            >
              <span>
                {{
                  contractServiceTypes[key].replace(
                    "assinatura",
                    subscriptionName
                  )
                }}
              </span>
            </option>
          </select>
          <div class="invalid-feedback">
            <span v-if="v$.servicesToContract.required.$invalid">
              Você deve selecionar uma opção
            </span>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <label for="supportFromAnotherConsultant" class="form-label">
            Houve auxílio de um PN?
          </label>
          <select
            id="supportFromAnotherConsultant"
            v-model="supportFromAnotherConsultant"
            class="form-select"
            :disabled="isDetail"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option value="yes">Sim</option>
            <option value="no" :disabled="isAnalyst">Não</option>
          </select>
        </div>
        <div
          v-if="supportFromAnotherConsultant === 'yes'"
          class="col-md-6 col-12 mt-3"
        >
          <label for="closerConsultantId" class="form-label">
            PN especialista
          </label>
          <select
            id="closerConsultantId"
            v-model="closerConsultantId"
            :disabled="isDetail"
            class="form-select"
            :class="{
              'is-invalid': v$.closerConsultantId.$error,
              'select-disabled': isDetail,
            }"
            @blur="preSave()"
          >
            <option
              v-for="consultant in closerConsultants"
              :key="consultant.id"
              :value="consultant.id"
            >
              {{ consultant.name }}
            </option>
          </select>
          <div class="invalid-feedback">
            <span v-if="v$.closerConsultantId.required.$invalid">
              Você deve selecionar uma opção
            </span>
          </div>
        </div>
      </div>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  email,
  requiredIf,
  minLength,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getProposalPayload } from "@/helpers/interview_payloads";
import { getPercentageFormat } from "@/helpers/formatting";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { floatToPercentage, percentageToFloat } from "@/helpers/converters";
import Spinner from "@/modules/shared/components/Spinner";
import CommentsSection from "./CommentsSection";
import Presentation from "@/modules/shared/components/Presentation";
import SubscriptionPlans from "./SubscriptionPlans";
import ConfirmModal from "@/modules/shared/components/ConfirmModal";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import * as subscriptionPlanTypes from "@/constants/subscriptionPlanTypes";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
export default {
  components: {
    Spinner,
    CommentsSection,
    Presentation,
    SubscriptionPlans,
    ConfirmModal,
    Swiper,
    SwiperSlide,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const parcels = _.range(2, 13);
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {};
    return {
      parcels,
      v$: useVuelidate({ $registerAs: props.interviewSection }),
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      selectedParcel: 12,
      parcelsQuantity: 1,
      recommendations: [],
      interest: 0,
      totalAmount: 0,
      installmentAmount: 0,
      consultantId: undefined,
      userConsultantId: undefined,
      discount: 0,
      recommendationDiscount: 0,
      servicesToContract: "",
      paymentMethod: "",
      supportFromAnotherConsultant: "no",
      closerConsultantId: undefined,
      token: "",
      subscriptionPlanId: undefined,
      loading: false,
      subscriptionPlansVisible: false,
      paymentForInterview: false,
      ownerLevel: undefined,
      loadingProposal: false,
      loadingRecs: false,
      errorPhone: false,
      cellPhoneWithError: [],
      onlySubscriptionKey: "only_subscription",
      discountsOpened: false,
      investmentPercentage: "",
      discountPercentage: "",
      additionPercentage: "",
    };
  },
  validations() {
    return {
      selectedParcel: {},
      parcelsQuantity: {},
      recommendations: {
        $each: helpers.forEach({
          id: {},
          name: { required },
          cellPhone: {
            minLength(value) {
              if (!value) {
                return true;
              } else {
                var trimmed = value.replace("+", "").replaceAll(" ", "");
                return trimmed.length >= 13;
              }
            },
          },

          email: { email },
          occupation: {},
          goal: {},
          comment: {},
          type: {},
        }),
        recommendationCellErrors() {
          return !this.errorPhone;
        },
      },
      servicesToContract: { required },
      closerConsultantId: {
        required: requiredIf(function () {
          return this.supportFromAnotherConsultant === "yes";
        }),
      },
      investmentPercentage: {
        minLength: minLength(7),
      },
      discountPercentage: {
        minLength: minLength(7),
        outOfBounds: function (value) {
          const convertedValue = percentageToFloat(value);
          return convertedValue >= 0 && convertedValue <= 30;
        },
      },
      additionPercentage: {
        minLength: minLength(7),
      },
    };
  },
  watch: {
    subscriptionPlanId(newVal) {
      if (newVal == subscriptionPlanTypes.FLUXO) {
        this.servicesToContract = this.onlySubscriptionKey;
      }
    },
  },
  computed: {
    ...mapGetters({
      goals: types.GOALS,
      contractServiceTypes: types.CONTRACT_SERVICE_TYPES,
      paymentMethodTypes: types.PAYMENT_METHOD_TYPES,
      closerConsultants: types.CLOSER_CONSULTANTS,
      calculation: types.PROPOSAL_CALCULATION,
      isAnalyst: accountTypes.IS_HUNTER,
      user: accountTypes.USER,
      subscriptionPlans: types.SUBSCRIPTION_PLANS,
      customerSubscriptionPlans: types.CUSTOMER_SUBSCRIPTION_PLANS,
      formatCurrency: types.FORMAT_CURRENCY,
    }),
    payments: function () {
      if (!this.paymentMethodTypes) return {};
      return Object.fromEntries(
        Object.entries(this.paymentMethodTypes).filter(
          ([key, value]) =>
            this.parcelsQuantity === 1 ||
            (this.parcelsQuantity > 1 && key === "credit")
        )
      );
    },
    differentConsultant: function () {
      return this.userConsultantId != this.consultantId && !this.isAnalyst;
    },
    plansPresentationUrl: function () {
      return process.env.VUE_APP_SUBSCRIPTION_PLANS_CANVA_PRESENTATION;
    },
    subscriptionName: function () {
      return (
        _.find(this.subscriptionPlans, { id: this.subscriptionPlanId })?.name ||
        "Assinatura"
      );
    },
    customerPlans: function () {
      if (this.customerSubscriptionPlans.subscription_plans_id) {
        return JSON.parse(this.customerSubscriptionPlans).subscription_plans_id;
      }
      return [
        subscriptionPlanTypes.SUPORTE,
        subscriptionPlanTypes.BASICO,
        subscriptionPlanTypes.ESSENCIAL,
        subscriptionPlanTypes.ELITE,
        subscriptionPlanTypes.FLUXO,
      ];
    },

    discount0: function () {
      return this.calculation?.discount_value_0 || 0;
    },
    discount5: function () {
      return this.calculation?.discount_value_5 || 0;
    },
    discount10: function () {
      return this.calculation?.discount_value_10 || 0;
    },

    discount15: function () {
      return this.calculation?.discount_value_15 || 0;
    },
    breakpoints() {
      return {
        768: {
          slidesPerView: 1.5,
        },
        992: {
          slidesPerView: 2.5,
        },
        1200: {
          slidesPerView: 3,
        },
        1400: {
          slidesPerView: this.subscriptionPlans?.length || 4,
        },
      };
    },
    percentageFormat: function () {
      return getPercentageFormat();
    },
  },
  async mounted() {
    if (this.isAnalyst && this.supportFromAnotherConsultant === "no") {
      this.supportFromAnotherConsultant = "yes";
    }
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getProposalContractServiceTypes: types.GET_CONTRACT_SERVICE_TYPES,
      getProposalPaymentMethodTypes: types.GET_PAYMENT_METHOD_TYPES,
      getSpecialistConsultants: types.GET_CLOSER_CONSULTANTS,
      getProposal: types.GET_PROPOSAL,
      getContracts: types.GET_CUSTOMER_CONTRACTS,
      getCustomerInfo: types.GET_CUSTOMER_INFO,
      getSubscriptionPlans: types.GET_SUBSCRIPTION_PLANS,
      getCustomerSubscriptionPlans: types.GET_CUSTOMER_SUBSCRIPTION_PLANS,
      autoSaveProposal: types.AUTO_SAVE_PROPOSAL,
      calculateProposal: types.CALCULATE_PROPOSAL,
      autoSaveRecommendation: types.AUTO_SAVE_RECOMMENDATION,
      deleteRecommendation: types.DELETE_RECOMMENDATION,
      checkDuplicateCellPhoneClient: sharedTypes.CHECK_DUPLICATE_CUSTOMER_PHONE,
      checkDuplicateCellPhonePn: sharedTypes.CHECK_DUPLICATE_CONSULTANT_PHONE,
    }),
    ...mapMutations({
      mutateProposal: types.MUTATE_PROPOSAL,
      changeLoadingState: types.MUTATE_INTERVIEW_PROPOSAL_LOADING,
    }),
    async fetchProposal() {
      this.loading = true;
      try {
        const response = await this.getProposal(this.$route.params.customerId);
        const {
          installment_quantity,
          services_to_contract,
          payment_method,
          closer_consultant_id,
          interest,
          total_amount,
          installment_amount,
          discount,
          recommendation_discount,
          recommendations,
          subscription_plan_id,
          investment_percentage,
          discount_percentage,
          addition_percentage,
        } = response.data;
        this.investmentPercentage = floatToPercentage(investment_percentage);
        this.discountPercentage = floatToPercentage(discount_percentage);
        this.additionPercentage = floatToPercentage(addition_percentage);
        this.selectedParcel =
          installment_quantity > 1 ? installment_quantity : 12;
        this.servicesToContract = services_to_contract;
        this.paymentMethod = payment_method;
        this.interest = interest;
        this.totalAmount = total_amount;
        this.installmentAmount = installment_amount;
        this.discount = discount;
        this.recommendationDiscount = recommendation_discount || 0;
        this.subscriptionPlanId =
          subscription_plan_id || subscriptionPlanTypes.ESSENCIAL;
        if (this.subscriptionPlanId === subscriptionPlanTypes.FLUXO) {
          this.servicesToContract = this.onlySubscriptionKey;
        }
        this.recommendations = recommendations.map((recommendation) => {
          const {
            id,
            name,
            cell_phone,
            email,
            occupation,
            goal,
            comment,
            type,
            customer_id,
          } = recommendation;
          return {
            id,
            name,
            cellPhone: cell_phone,
            email,
            occupation,
            goal,
            comment,
            type,
            customer_id,
          };
        });
        const customerInfoResponse = await this.getCustomerInfo(
          this.$route.params.customerId
        );
        this.consultantId = customerInfoResponse.data?.consultant_id;
        this.userConsultantId = this.user?.user?.appify_consultant_id;
        this.ownerLevel =
          customerInfoResponse.data?.customer_consultant?.id_consultant_role;
        if (
          this.isAnalyst ||
          closer_consultant_id ||
          this.differentConsultant
        ) {
          this.supportFromAnotherConsultant = "yes";
        } else {
          this.supportFromAnotherConsultant = "no";
        }
        if (closer_consultant_id) {
          this.closerConsultantId = closer_consultant_id;
        } else if (this.differentConsultant) {
          this.closerConsultantId = this.userConsultantId;
        }
        this.changeParcelsQuantity(installment_quantity);
        const payload = getProposalPayload(this);
        this.mutateProposal(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter a proposta do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
        this.changeLoadingState(false);
      }
    },
    async fetchAll() {
      this.changeLoadingState(true);
      try {
        await Promise.all([
          this.fetchProposal(),
          this.getProposalContractServiceTypes(),
          this.getProposalPaymentMethodTypes(),
          this.getSpecialistConsultants(),
          this.getSubscriptionPlans(),
          this.getCustomerSubscriptionPlans(this.token),
        ]);

        if (this.isInterview) {
          await this.getContracts(this.token);
        }
      } catch (error) {
      } finally {
        this.changeLoadingState(false);
      }
    },

    addRecommendation(leadType) {
      this.recommendations.push({
        id: "",
        name: "",
        cellPhone: "",
        email: "",
        occupation: "",
        goal: "",
        comment: "",
        type: leadType,
      });
    },

    async checkCellPhone(rec, index) {
      try {
        var trimmed = rec.cellPhone.replace("+", "").replaceAll(" ", "");
        const verifyLength = trimmed.length >= 13;
        if (verifyLength) {
          if (rec.type === "PCL") {
            await this.checkDuplicateCellPhoneClient({
              phone: trimmed,
              customerId: this.$route.params.customerId,
            });
          }
          if (rec.type === "PPN") {
            await this.checkDuplicateCellPhonePn({
              cell: trimmed,
              id: rec.customer_id,
            });
          }
        }

        this.cellPhoneWithError[index] = false;
        this.errorPhone = this.cellPhoneWithError[index];
      } catch (error) {
        this.cellPhoneWithError[index] = true;
        this.errorPhone = this.cellPhoneWithError[index];

        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível cadastrar esse contato pois já é um cliente futuro.",
          ],
        });
      }
    },

    async removeRecommendation(id) {
      this.loadingProposal = true;
      this.deleteRecommendation(id)
        .then((response) => {
          this.fetchProposal();
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              `${error?.response?.data?.message}.` ||
                "Não foi possível excluir a recomendação.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loadingProposal = false;
        });
    },
    removeRecWhenFieldsEmpty(i) {
      this.recommendations.splice(i, 1);
    },
    preSave() {
      const payload = getProposalPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateProposal(payload);
        this.autoSave(payload);
      }
    },
    async autoSave(payload) {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.autoSaveProposal(payload)
        .then((response) => {
          this.calculate();
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente a proposta do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async autoSaveRecommendations() {
      this.loading = true;

      if (this.v$.recommendations.$error) return;
      const payload = getProposalPayload(this);
      const currentPayload = JSON.stringify(payload);

      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateProposal(payload);
      }

      try {
        this.loadingRecs = true;
        await this.autoSaveRecommendation({
          token: payload.token,
          recommendations: payload.recommendations,
        });
        const hasEmptyIds = payload.recommendations.some(
          (recommendation) => !recommendation.id
        );
        if (hasEmptyIds) {
          this.loadingProposal = true;
          this.loadingRecs = true;
          const response = await this.getProposal(
            this.$route.params.customerId
          );
          if (response?.data?.recommendations) {
            const { recommendations } = response.data;
            this.recommendations = recommendations.map((recommendation) => {
              const {
                id,
                name,
                cell_phone,
                email,
                occupation,
                goal,
                comment,
                type,
              } = recommendation;
              return {
                id,
                name,
                cellPhone: cell_phone,
                email,
                occupation,
                goal,
                comment,
                type,
              };
            });
          }
        }
        this.calculate();
      } catch (error) {
        if (error.response.status === 406) {
          this.$vfm.show("message", {
            type: "error",
            messages: ["Celular já existente em nosso banco de dados."],
          });
        } else {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente as recomendações do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        }
      } finally {
        this.loading = false;
        this.loadingRecs = false;
        this.loadingProposal = false;
      }
    },

    async saveRecommendation() {
      this.loadingProposal = true;
      this.fetchProposal()
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar a recomendação.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loadingProposal = false;
        });
    },
    onChangeParcels(event) {
      this.changeParcelsQuantity(event.target.value);
    },
    changeParcelsQuantity(value) {
      if (!this.isDetail) {
        if (this.parcelsQuantity !== value) {
          this.paymentMethod = "credit";
        }
        this.parcelsQuantity = value;
        if (this.calculation?.parcels_values) {
          const parcelValues =
            this.calculation.parcels_values[
              (this.parcelsQuantity - 1).toString()
            ];
          this.interest = parcelValues.interest;
          this.totalAmount = parcelValues.total_amount;
          this.installmentAmount = parcelValues.installment_amount;
          this.discount = parcelValues.discount;
          this.preSave();
        }
      }
    },
    calculate() {
      this.loading = true;
      this.calculateProposal(this.token)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível obter os dados de proposta do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async setRecommendationDiscount(value, active) {
      if (typeof active === "undefined") {
        this.$vfm.show("message", {
          type: "error",
          messages: ["Por favor espere o carregamento dos dados"],
        });
        return false;
      }

      if (active === false) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Esse Desconto não está disponível devido ao valor minimo da EP",
          ],
        });
        return false;
      } else {
        const isFormCorrect = await this.v$.$validate();
        if (!isFormCorrect) return;
        if (!this.isDetail && value !== this.recommendationDiscount) {
          this.recommendationDiscount = value;
          this.changeParcelsQuantity(this.parcelsQuantity);
        }
      }
    },
    setSubscription(plan) {
      if (!this.isDetail) {
        this.subscriptionPlanId = plan.id;
        this.calculation.subscription_amount = plan.amount;
        this.preSave();
      }
    },
    isServicesToContractOptionDisabled(key) {
      if (
        this.subscriptionPlanId == subscriptionPlanTypes.FLUXO &&
        key !== this.onlySubscriptionKey
      ) {
        // If it's a FLUXO plan and the key is not the only subscription key, disable the option
        return true;
      }
      // Otherwise, do not disable the option
      return false;
    },
    openConfimationModal(id) {
      this.$vfm.show(
        {
          component: ConfirmModal,
        },
        {
          messages: [`Deseja excluir <b>${id.name}</b>?`],
          handleConfirm: () => this.removeRecommendation(id),
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 800px) {
  .ribbon {
    padding-right: 295px !important;
  }
}
.blue-card {
  padding: 30px 20px;
  width: 500px;
  height: 300px;
  h1 {
    font-size: 25px !important;
    font-weight: 600;
    text-align: center;
  }
}
.final-value {
  font-size: 26px !important;
  line-height: 1.2 !important;
  font-weight: 600;
  color: #ffeb98;
}
.recommendation-discount-value {
  display: flex;
  flex-direction: column;
  border-radius: 100px;
  border: solid 1px white;
  padding: 12px;
  color: white;
  &.selected {
    background-color: white;
    color: var(--black-txt);
  }
}
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  padding-right: 515px;
  padding-bottom: 315px;
}
.ribbon__content {
  left: -50px;
  top: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: var(--orange);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  border: 2px dotted #fff;
  outline: 5px solid var(--orange);
}
.ribbon::before {
  top: 0;
  left: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  box-sizing: content-box;
}
.ribbon::after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #f19066;
}
.subscription-card {
  width: calc(20% - 10px);
  height: 280px;
}
.subscription-ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  padding-right: 290px;
  padding-bottom: 290px;
}
.subscription-ribbon__content {
  left: -2px;
  top: 24px;
  font-size: 0.7em;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  display: block;
  width: 140px;
  padding: 3px 0;
  background-color: var(--orange);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  border: 2px dotted #fff;
  outline: 5px solid var(--orange);
}
.error-bg {
  background-color: rgba(220, 53, 69, 0.8);
}

.margin-button {
  margin-top: 35px;
}

@media (max-width: 767.98px) {
  .column-mobile {
    flex-direction: column-reverse;
  }

  .margin-button {
    margin-top: 0px;
  }
}

.disabled {
  // opacity: 0.6;
  // pointer-events: none;
  // color: #ccc;
}

option:disabled {
  background-color: #f0f0f0 !important; /* Change the background color */
  color: #ccc !important; /* Change the text color */
}

.container-plans {
  display: flex;
  justify-content: center;
  z-index: 1;

  .card-best-choice {
    background-color: #006cff !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .card-head-best-choice {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        font-size: 1rem;
        margin-bottom: 0;
      }

      .bg-star {
        width: 40px;
        height: 40px;

        @media (min-width: 1400px) {
          width: 30px;
          height: 30px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .card-title {
      margin-top: 0 !important;
    }
  }

  .card-plans {
    width: 340px;
    background-color: #254493;
    padding: 1.5rem;
    border-radius: 20px;
    margin-right: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    min-height: 580px;

    @media (min-width: 1400px) {
      width: 240px;
    }

    &:last-child {
      margin-right: 0;
    }

    .card-head-best-choice,
    .card-title,
    .card-price {
      margin-bottom: 1.25rem;
    }

    .card-title {
      margin-top: 1.5rem;
      min-height: 52px;
    }

    .card-price {
      h1 {
        display: flex;
        align-items: baseline;
        font-size: 1.7em;
        p {
          color: #dfecff;
          font-weight: normal;
          font-size: 0.85em;
          margin-bottom: 0;
        }
      }
    }

    .card-description {
      .card-detail {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        background-color: rgba(255, 255, 255, 0.2);
        padding: 0.5rem 1rem;
        border-radius: 80px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 0.75rem;

          @media (min-width: 1400px) {
            width: 17px;
            height: 17px;
          }
        }

        span {
          @media (min-width: 1400px) {
            font-size: 0.85em;
          }
        }
      }

      .not-included {
        opacity: 0.6;
      }
    }
  }
}

.card-font {
  @media (min-width: 1400px) {
    font-size: 1.35em;
  }
}

.choice-border {
  border: 3px solid #ff9d00;
}

::v-deep(.swiper-pagination-bullet) {
  width: 12px;
  height: 12px;
  background: #d9d9d94d !important;
  opacity: 1;
}

::v-deep(.swiper-pagination-bullet-active) {
  background: #ff9d00 !important;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
</style>
