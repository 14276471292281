import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  headerType: undefined,
  legalTypes: {},
  maritalStatuses: {},
  addressStates: {},
  countriesList: {},
  genders: {},
  priorities: {},
  incomeTaxCategoryTypes: {},
  investorProfile: {},
  transactionPaymentTypes: {}
}

export default {
  state,
  actions,
  getters,
  mutations
}
