<template>
  <form
    v-if="isInterview"
    id="futuro-customer-value"
    class="my-5"
    @submit.prevent
  >
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            v-if="!monthlyAverage"
            src="~@/assets/img/chevron-down-solid.svg"
            alt="Futuro | Consultoria Financeira"
            @click="monthlyAverage = !monthlyAverage"
          />
          <img
            v-if="monthlyAverage"
            src="~@/assets/img/chevron-up-solid.svg"
            alt="Futuro | Consultoria Financeira"
            @click="monthlyAverage = !monthlyAverage"
          />
          <h2>Investimento mensal</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div v-if="monthlyAverage && !loading">
        <div class="row mt-3 mb-3 px-3">
          <div class="col-12 blue-card totalizer">
            <span>Renda bruta anual:</span>
            <span>{{ formatCurrency(calculation.annual_gross, "BRL") }}</span>
          </div>
        </div>
        <div class="row justify-content-center mt-3 mb-3">
          <div class="col-auto blue-card monthly-value">
            <h1>Mensal</h1>
            <p>
              <b>{{ formatCurrency(calculation.monthly_value, "BRL") }}</b>
              mensais
            </p>
          </div>
        </div>
        <div class="row justify-content-center mt-3 mb-3">
          <p class="col-auto">
            À partir do segundo ano:
            <b>{{ formatCurrency(calculation.subscription_amount, "BRL") }}</b>
            + projeto (dependendo da sua necessidade)
          </p>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import Spinner from "../../shared/components/Spinner";
export default {
  components: {
    Spinner,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      monthlyAverage: false,
    };
  },
  computed: {
    ...mapGetters({
      calculation: types.PROPOSAL_CALCULATION,
      loading: types.LOADING_CALCULATION_PER_MONTH,
      formatCurrency: types.FORMAT_CURRENCY,
    }),
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 16px;
  margin: 0;
  b {
    font-weight: 600;
  }
}
.blue-card {
  &.totalizer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;
  }
  &.monthly-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    height: 300px;
    padding: 35px 55px;
    h1 {
      margin-bottom: 20px;
    }
  }
}
</style>
