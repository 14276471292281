import axios from 'axios'
import * as types from './types'

export default {
  [types.GET_CHECKOUT]: ({ commit }, payload) => {
    return axios.get(`/api/checkouts/${payload}`)
      .then(resp => {
        commit(types.MUTATE_CHECKOUT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.RESEND_SIGN_EMAIL]: ({ commit }, payload) => {
    return axios.post(`/api/checkouts/resend_email/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.PAY_SUBSCRIPTION]: ({ commit }, payload) => {
    return axios.post('/api/payments/subscription', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.PAY_INTERVIEW]: ({ commit }, payload) => {
    return axios.post('/api/payments/interview', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CHECKOUT_STATUS]: ({ commit }, payload) => {
    return axios.get(`/api/checkouts/status/${payload}`)
      .then(resp => {
      //  commit(types.MUTATE_INTERVIEW_CHECKOUT_STATUS, resp.data.status)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
}
