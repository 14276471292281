import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  savingInterview: false,
  customerAlteredInterview: false,
  comments: {},
  customerInfo: {},
  goals: {},
  selectedGoals: {},
  lifeInsurance: {},
  acquisitions: {},
  patrimony: {},
  healthPlan: {},
  retirement: {},
  monthlyInvestment: {},
  monthlyInvestmentCalculation: {},
  debts: [],
  contractServiceTypes: {},
  paymentMethodTypes: {},
  closerConsultants: [],
  proposal: {},
  occupations: [],
  proposalCalculation: {},
  cashFlowCategories: [],
  cashFlow: {},
  cashFlowCalculation: {},
  additionalInformation: {},
  investments: {},
  interviewForm: undefined,
  customerContracts: [],
  interviewCashFlowLoading: false,
  interviewProposalLoading: false,
  subscriptionPlans: [],
  customerSubscriptionPlans: {},
  searchInterview: [],
  setCustomerSatisfactionSurvey: {},
  loadingCalculationPerMonth: false,
  loadingProposal: false,
  currentCurrency: "BRL",
  transactionPayments: [],
};

export default {
  state,
  actions,
  getters,
  mutations,
};
