<template>
  <form id="futuro-customer-additional" class="my-5" @submit.prevent>
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/plus-circle.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Informações adicionais</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mb-3 gy-3 align-items-end">
        <div class="col-md-4 col-12">
          <label for="incomeTaxCategory" class="form-label">
            Imposto de renda
          </label>
          <select
            id="incomeTaxCategory"
            v-model="incomeTaxCategory"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option
              v-for="key in Object.keys(incomeTaxCategoryTypes)"
              :key="key"
              :value="key"
            >
              {{ incomeTaxCategoryTypes[key] }}
            </option>
          </select>
        </div>
        <div
          v-if="incomeTaxCategory && incomeTaxCategory !== 'isento'"
          class="col-md-4 col-12"
        >
          <label for="incomeTaxPaid" class="form-label">
            Você pagou ou teve o imposto restituído?
          </label>
          <select
            id="incomeTaxPaid"
            v-model="incomeTaxPaid"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option value="yes">Pago</option>
            <option value="no">Restituído</option>
          </select>
        </div>
        <div
          v-if="
            incomeTaxCategory &&
            incomeTaxCategory !== 'isento' &&
            incomeTaxPaid === 'yes'
          "
          class="col-md-4 col-12"
        >
          <label for="incomeTaxPaidAmount" class="form-label">
            Qual valor você pagou de imposto de renda no último ano?
          </label>
          <input
            id="incomeTaxPaidAmount"
            v-model.lazy="incomeTaxPaidAmount"
            v-money="moneyFormat"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': v$.incomeTaxPaidAmount.minLength.$invalid }"
            @blur="preSave()"
          />
          <div class="invalid-feedback">
            <span v-if="v$.incomeTaxPaidAmount.minLength.$invalid">
              O valor é inválido
            </span>
          </div>
        </div>
        <div
          v-if="
            incomeTaxCategory &&
            incomeTaxCategory !== 'isento' &&
            incomeTaxPaid === 'no'
          "
          class="col-md-4 col-12"
        >
          <label for="incomeTaxRefundedAmount" class="form-label">
            Qual valor foi restituído de imposto de renda no último ano?
          </label>
          <input
            id="incomeTaxRefundedAmount"
            v-model.lazy="incomeTaxRefundedAmount"
            v-money="moneyFormat"
            type="text"
            :disabled="isDetail"
            class="form-control"
            :class="{
              'is-invalid': v$.incomeTaxRefundedAmount.minLength.$invalid,
            }"
            @blur="preSave()"
          />
          <div class="invalid-feedback">
            <span v-if="v$.incomeTaxRefundedAmount.minLength.$invalid">
              O valor é inválido
            </span>
          </div>
        </div>
      </div>

      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, minLength } from "@vuelidate/validators";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { VMoney } from "v-money";
import {
  getPercentageFormat,
  getFloatFormat,
} from "@/helpers/formatting";
import { isYear } from "../../../validators/string";
import {
  floatToMoney,
  moneyToFloat,
  integerToString,
  floatToPercentage,
  booleanToString,
  stringToFloat,
} from "@/helpers/converters";
import { getAdditionalInformationPayload } from "@/helpers/interview_payloads";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import Spinner from "@/modules/shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      incomeTaxCategory: "",
      incomeTaxPaid: "",
      incomeTaxPaidAmount: "",
      incomeTaxRefundedAmount: "",
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      incomeTaxCategory: {},
      incomeTaxPaid: {},
      incomeTaxPaidAmount: {
        minLength: minLength(7),
      },
      incomeTaxRefundedAmount: {
        minLength: minLength(7),
      },
    };
  },
  computed: {
    ...mapGetters({
      incomeTaxCategoryTypes: sharedTypes.INCOME_TAX_CATEGORY_TYPES,
      formatCurrency: types.FORMAT_CURRENCY,
      formatedCurrency: types.FORMATED_CURRENCY
    }),
    moneyFormat: function () {
      return this.formatedCurrency;
    },
    percentageFormat: function () {
      return getPercentageFormat();
    },
    floatFormat: function () {
      return getFloatFormat();
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getAdditionalInformation: types.GET_ADDITIONAL_INFORMATION,
      autoSaveAdditionalInformation: types.AUTO_SAVE_ADDITIONAL_INFORMATION,
      getIncomeTaxCategoryTypes: sharedTypes.GET_INCOME_TAX_CATEGORY_TYPES,
    }),
    ...mapMutations({
      mutateAdditionalInformation: types.MUTATE_ADDITIONAL_INFORMATION,
    }),
    async fetchAdditionalInformation() {
      this.loading = true;
      try {
        const response = await this.getAdditionalInformation(
          this.$route.params.customerId
        );
        const {
          income_tax_category,
          income_tax_paid,
          income_tax_paid_amount,
          income_tax_refunded_amount,
        } = response.data;
        this.incomeTaxCategory = income_tax_category;
        this.incomeTaxPaid = booleanToString(income_tax_paid);
        this.incomeTaxPaidAmount = floatToMoney(income_tax_paid_amount);
        this.incomeTaxRefundedAmount = floatToMoney(income_tax_refunded_amount);
        const payload = getAdditionalInformationPayload(this);
        this.mutateAdditionalInformation(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter as informações adicionais do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([
          this.fetchAdditionalInformation(),
          this.getIncomeTaxCategoryTypes(),
        ]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    preSave() {
      const payload = getAdditionalInformationPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateAdditionalInformation(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveAdditionalInformation(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente as informações adicionais do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.totalizer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px;
  &.debts-value-container {
    background-image: var(--red-gradient);
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}
</style>
