<template>
  <div class="dark bg-with-image main-container pt-0 pb-0">
    <message-modal />
    <div class="container-fluid h-100">
      <div
        v-if="loading"
        class="d-flex flex-column align-items-center justify-content-center h-100 p-5"
      >
        <img
          src="~@/assets/img/logo-white.svg"
          height="150"
          alt="Futuro | Consultoria Financeira"
        />
        <span class="mt-4"
          >Estamos obtendo as informações do seu investimento</span
        >
        <span>Aguarde um momento...</span>
        <spinner class="mt-3" size="large" />
      </div>
      <div
        v-else-if="!loading && !error"
        class="d-flex flex-column justify-content-center h-100"
      >
        <div class="row mx-md-5 mx-2">
          <img
            src="~@/assets/img/logo-white.svg"
            alt="Futuro | Consultoria Financeira"
            class="logo mt-2 mb-4"
          />
          <div class="col-12">
            <h1 class="page-title-checkout mb-2">É muito bom ter você aqui!</h1>
          </div>
          <div class="col-12">
            <p class="mb-0">Convidamos você a turbinar o seu Futuro conosco.</p>
            <p>Para isso, precisamos só de alguns dados. É rapidinho!</p>
          </div>
        </div>
        <div class="row mx-1 align-items-start justify-content-center">
          <div class="card col-md-5 col-12 px-5 my-3">
            <div class="row gy-3 mb-0">
              <div class="col-12">
                <label for="name" class="form-label"> Nome completo </label>
                <input
                  id="name"
                  v-model="name"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.name.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.name.required.$invalid">
                    Você deve informar um nome
                  </span>
                </div>
              </div>
              <div class="col-12">
                <label for="cellPhone" class="form-label"> Telefone </label>
                <vue-tel-input
                  id="cellPhone"
                  v-model="cellPhone"
                  :class="{ 'is-invalid': v$.cellPhone.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.cellPhone.required.$invalid">
                    Você deve informar um telefone celular
                  </span>
                  <span
                    v-if="
                      v$.cellPhone.minLength.$invalid ||
                      v$.cellPhone.maxLength.$invalid
                    "
                  >
                    O telefone celular informado é inválido
                  </span>
                </div>
              </div>
              <div
                class="col-12 d-flex mt-4 align-items-center justify-content-center"
              >
                <spinner v-if="saving" class="me-2" />
                <div class="col-auto">
                  <button
                    :disabled="saving"
                    class="btn btn-orange"
                    @click.prevent="save"
                  >
                    {{
                      saving
                        ? "Por favor, aguarde um momento..."
                        : "Quero turbinar o meu Futuro!"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex flex-column justify-content-center h-100 container"
      >
        <div class="row">
          <img
            src="~@/assets/img/logo-white.svg"
            alt="Futuro | Consultoria Financeira"
            class="logo mt-2 mb-4"
          />
          <div class="col-12">
            <h1 class="page-title-checkout mb-2">Oops!</h1>
          </div>
          <div class="col-12">
            <p class="mb-0">Não encontramos a página que você solicitou.</p>
            <p>Mas temos outras opções para você:</p>
          </div>
          <div class="col-12 row justify-content-center">
            <a class="btn btn-red col-md-4 col-8 mb-4" :href="futuroUrl">
              Quero saber as vantagens de ser Futuro
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { onlyDigits } from "@/helpers/converters";
import Spinner from "@/modules/shared/components/Spinner";
import MessageModal from "@/modules/shared/components/MessageModal";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
export default {
  components: {
    MessageModal,
    Spinner,
  },
  props: {
    alias: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      error: false,
      saving: false,
      consultantId: undefined,
      name: "",
      cellPhone: "+55 ",
      campaignId: undefined,
    };
  },
  validations() {
    return {
      name: { required },
      cellPhone: {
        required,
        minLength(value) {
          return value.length >= 9;
        },
        maxLength(value) {
          return value.length <= 20;
        },
      },
    };
  },
  computed: {
    futuroUrl: function () {
      return process.env.VUE_APP_WEBSITE;
    },
  },
  mounted() {
    this.getcampaign();
  },
  methods: {
    ...mapActions({
      saveLead: types.SAVE_LEAD,
      getcampaignFromAlias: types.GET_CAMPAIGN_FROM_ALIAS,
      checkDuplicateCustomerPhone: sharedTypes.CHECK_DUPLICATE_CUSTOMER_PHONE,
    }),
    checkCellPhone(phoneNumber) {
      var formatedPhoneNumber = phoneNumber
        .replace("+", "")
        .replaceAll(" ", "");
      const lengthCheck = formatedPhoneNumber.length >= 13;
      if (lengthCheck) {
        return this.checkDuplicateCustomerPhone({ phone: formatedPhoneNumber });
      }
      return new Promise();
    },
    async save() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const { consultantId, name, cellPhone, campaignId } = this;

      try {
        await this.checkCellPhone(cellPhone);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível cadastrar esse contato pois este já é um cliente futuro.",
          ],
        });
        this.saving = false;
        return;
      }

      this.saveLead({
        consultant_id: consultantId,
        name,
        cell_phone: onlyDigits(cellPhone),
        campaign_id: campaignId,
      })
        .then((response) => {
          const { token_public, token_customer } = response.data;
          this.$router.push(
            `/autenticacao_cliente/${token_public}?back_url=pre_entrevista/${token_customer}`
          );
        })
        .catch((error) => {
          const message = error.response?.data?.message;
          let modalObject = {
            type: "error",
          };
          if (message) {
            modalObject = {
              ...modalObject,
              messages: [message],
            };
          } else {
            modalObject = {
              ...modalObject,
              messages: [
                "Não foi possível registrar suas informações.",
                "Por favor, verifique sua conexão com a Internet e tente novamente.",
              ],
            };
          }
          this.$vfm.show("message", modalObject);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getcampaign() {
      this.loading = true;
      this.error = false;
      const { userName, alias } = this.$route.params;

      this.getcampaignFromAlias({
        user_name: userName,
        alias,
      })
        .then((response) => {
          if (response.data.id) {
            this.campaignId = response.data.id;
            this.consultantId = response.data.consultant_id;
          } else {
            this.error = true;
          }
        })
        .catch((error) => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
