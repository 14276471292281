<template>
  <form id="futuro-customer-retirement" class="my-5" @submit.prevent>
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-retirement.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Aposentadoria</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mb-3 gy-3">
        <div class="col-md-6 col-12">
          <label for="retirementAge" class="form-label"
            >Com quantos anos você gostaria de se aposentar?</label
          >
          <input
            id="retirementAge"
            v-model="retirementAge"
            type="number"
            :disabled="isDetail"
            class="form-control"
            @blur="preSave()"
          />
        </div>
      </div>
      <div class="row mb-3 gy-3">
        <div class="col-md-6 col-12">
          <label for="desiredMonthlyAmount" class="form-label"
            >Qual a renda mensal desejada?</label
          >
          <input
            id="desiredMonthlyAmount"
            v-model.lazy="desiredMonthlyAmount"
            v-money="moneyFormat"
            type="text"
            :disabled="isDetail"
            class="form-control"
            :class="{ 'is-invalid': v$.desiredMonthlyAmount.$error }"
            @blur="preSave()"
          />
          <div class="invalid-feedback">
            <span v-if="v$.desiredMonthlyAmount.minLength.$invalid"
              >O valor é inválido</span
            >
          </div>
        </div>
      </div>
      <div class="row mb-3 gy-3">
        <div class="col-md-6 col-12">
          <label for="isSaving" class="form-label"
            >Já poupa para aposentadoria?</label
          >
          <select
            id="isSaving"
            v-model="isSaving"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @change="handleSavingChange()"
          >
            <option value="" disabled selected>Selecione</option>
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
        </div>
      </div>
      <div v-if="isSaving === 'yes'" class="row mb-3 gy-3">
        <div class="col-md-6 col-12">
          <label for="wantSave" class="form-label">Quanto você poupa?</label>
          <input
            id="wantSave"
            v-model.lazy="wantSave"
            v-money="moneyFormat"
            :disabled="isDetail"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': v$.wantSave.$error }"
            @blur="handleSavingChange()"
          />
          <div class="invalid-feedback">
            <span v-if="v$.wantSave.minLength.$invalid"
              >O valor é inválido</span
            >
          </div>
        </div>
      </div>
      <template v-if="isInterview">
        <div class="row mb-3 gy-3">
          <div class="col-12">
            <label for="strategy" class="form-label"
              >Você tem alguma estratégia para alcançar este objetivo ou contará
              apenas com o INSS?</label
            >
            <textarea
              id="strategy"
              v-model="strategy"
              type="text"
              class="form-control"
              :disabled="isDetail"
              rows="4"
              @blur="preSave()"
            />
          </div>
        </div>
      </template>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { minLength } from "@vuelidate/validators";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { integerToString, floatToMoney } from "@/helpers/converters";
import { getRetirementPayload } from "@/helpers/interview_payloads";
import * as types from "../store/types";
import Spinner from "@/modules/shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      retirementAge: "",
      desiredMonthlyAmount: "",
      wantSave: "",
      strategy: "",
      token: "",
      isSaving: "",
      loading: false,
    };
  },
  validations() {
    return {
      retirementAge: {},
      desiredMonthlyAmount: {
        minLength: minLength(7),
      },
      wantSave: {
        minLength: minLength(7),
      },
      strategy: {},
    };
  },
  computed: {
    ...mapGetters({
      formatedCurrency: types.FORMATED_CURRENCY,
    }),
    moneyFormat: function () {
      return this.formatedCurrency
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getRetirement: types.GET_RETIREMENT,
      autoSaveRetirement: types.AUTO_SAVE_RETIREMENT,
    }),
    ...mapMutations({
      mutateRetirement: types.MUTATE_RETIREMENT,
    }),
    async fetchRetirement() {
      this.loading = true;
      try {
        const response = await this.getRetirement(
          this.$route.params.customerId
        );
        const { retirement_age, is_saving, desired_monthly_amount, want_save, strategy } =
          response.data;
        this.retirementAge = integerToString(retirement_age);
        this.desiredMonthlyAmount = floatToMoney(desired_monthly_amount);
        this.wantSave = floatToMoney(want_save);
        this.isSaving = is_saving ? "yes" : "no";
        this.strategy = strategy;
        const payload = getRetirementPayload(this);
        this.mutateRetirement(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter os dados de aposentadoria do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchRetirement()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    handleSavingChange() {
      if (this.isSaving === "no") {
        this.wantSave = "0";
        
        this.$emit("remove-saving-subcategory", {
          title: "Aposentadoria",
          category: "investimento",
          value: this.wantSave,
          categoryIndex: 1
        });
      }

      if (this.isSaving === "yes" && this.wantSave) {
        this.$emit("add-saving-subcategory", {
          title: "Aposentadoria",
          category: "investimento",
          value: this.wantSave,
          categoryIndex: 1
        });
      }

      this.preSave();
    },

    preSave() {
      const payload = getRetirementPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateRetirement(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveRetirement(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente os dados de aposentadoria do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
