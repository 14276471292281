import { getAge } from '../helpers/converters'

export const isLegalAge = (value) => {
  const age = getAge(value)
  if (age >= 18 || age < 0) {
    return true
  }
  return false
}

export const isValidDate = (value) => {
  if (!value) {
    return true
  }
  if (value.length !== 10) {
    return false
  }
  const dateStr = value.split('/')
  const dateResult = dateStr[2] + '-' + dateStr[1] + '-' + dateStr[0]
  const dateObj = new Date(dateResult)
  if (dateObj instanceof Date && !isNaN(dateObj)) {
    return true
  }
  return false
}
